import { Box } from "@material-ui/core";
import React from "react";

const FlexBoxColumn = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={props.alignItems ?? "center"}
      {...props}
    ></Box>
  );
};

export default FlexBoxColumn;
