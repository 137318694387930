const month = [
  "januari",
  "februari",
  "mars",
  "april",
  "maj",
  "juni",
  "juli",
  "augusti",
  "september",
  "oktober",
  "november",
  "december",
];

const pad = (number) => {
  return ("0" + number).slice(-2);
};

/**
 * Ex: "1 november 2021, 22:58"
 * @param {Date} date
 * @returns {string}
 */
export const dateToString = (date) => {
  return (
    `${date.getDate()} ` +
    `${month[date.getMonth()]} ` +
    `${date.getFullYear()}, ` +
    `${pad(date.getHours())}:` +
    `${pad(date.getMinutes())}`
  );
};

/**
 * Returns the days between two dates
 * The days have an extra 35 minutes to them in total so
 * 5 days and 35 minutes are 5 days
 * 5 days and 40 minutes are 6 days
 * The days become ceiled and can never be 0
 * @param {Date} firstDate
 * @param {Date} secondDate
 * @returns {number}
 */
export const daysBetween = (firstDate, secondDate) => {
  let totalTime = msBetween(firstDate, secondDate);
  const dayInMs = 24 * 60 * 60 * 1000;

  // Allow a day to be 1 day + 35 min
  totalTime = Math.max(1, totalTime - 35 * 60 * 1000);

  return Math.ceil(totalTime / dayInMs);
};

/**
 * Returns the hours between two dates.
 * The hours is ceiled so 1.4 hours becomes 2 hours
 * @param {Date} firstDate
 * @param {Date} secondDate
 * @returns {number}
 */
export const hoursBetween = (firstDate, secondDate) => {
  const totalTime = msBetween(firstDate, secondDate);
  const hourInMs = 60 * 60 * 1000;

  return Math.ceil(totalTime / hourInMs);
};

/**
 * The milliseconds between two dates, takes timezones into account
 * 12:00 on friday to 12:00 on saturday is still 24 hours even if
 * it changes to summer/winter-time
 * @param {Date} firstDate
 * @param {Date} secondDate
 * @returns {number}
 */
export const msBetween = (firstDate, secondDate) => {
  const minuteInMs = 60 * 1000;
  let diff = secondDate - firstDate;
  let timezoneDiff =
    secondDate.getTimezoneOffset() - firstDate.getTimezoneOffset();
  return diff - timezoneDiff * minuteInMs;
};
