import React from 'react';
import FlexBoxRow from "../flexBox/FlexBoxRow";
import {Box, Button, Container, makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import ArrowRight from "../../assets/icons/arrow_right.svg"
import ArrowLeft from "../../assets/icons/arrow_left.svg"
import PinsIcon from "../../assets/icons/pins.svg"
import TransmissionIcon from "../../assets/icons/cardIcons/transmission.svg"
import DropdownArrow from "../../assets/icons/dropdown_arrow_red.svg"
import {Text, TextBold} from "../text/Text";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import {getSearchParams} from "../../utils/Search";
import {useParams} from "react-router-dom";
import Moment from "moment";
import 'moment/locale/sv';

const SearchMini = ({startDate, endDate, distance, transmission, toggleIsOpenSearchMini}) => {

  const xs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const md = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#EEEEEE", height: "75px", alignItems: "center", display: "flex",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#FFF",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
      },
    },
    button: {
      "& .MuiBox": {
        backgroundColor: "#EEEEEE", height: "75px", alignItems: "center", display: "flex",
        [theme.breakpoints.down("sm")]: {
          backgroundColor: "#FFF",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
        },
      },
    },
  }));
  const classes = useStyles();

  return(
    <Box
      className={classes.root}>
      <Container maxWidth={"md"}>
        <FlexBoxRow justifyContent={"space-between"}>
        <Box minWidth={["180px", "85%", "85%"]}>
        {xs &&
        <FlexBoxRow justifyContent={"start"} py={"4px"} mx={"8px"}>
          <img src={ArrowRight} style={{width: sm? "14px":"24px", marginRight: sm? "8px":"14px"}}/>
          <FlexBoxColumn style={{alignItems: "start"}}>
            <TextBold fontSize={["13px", "14px", "18px"]} style={{color: "#494949"}}>
              {Moment(startDate).format("DD MMMM HH:mm")} - {Moment(endDate).format("DD MMMM HH:mm")}
            </TextBold>
          </FlexBoxColumn>
        </FlexBoxRow>
        }
        <FlexBoxRow justifyContent={"space-between"} width={"auto"}>
          {!xs &&
          <FlexBoxRow mx={"8px"}>
            <img src={ArrowRight} style={{width: sm? "14px":"24px", marginRight: sm? "8px":"14px"}}/>
            <FlexBoxColumn style={{alignItems: "start"}}>
              <Text fontSize={["12px", "14px", "18px"]} style={{color: "#494949"}}>Upphämtning</Text>
              <Text fontSize={["11px", "13px", "16px"]} style={{color: "#6C6C6C"}}>{startDate}</Text>
            </FlexBoxColumn>
          </FlexBoxRow>
          }
          {!xs &&
          <FlexBoxRow mx={"8px"}>
            <img src={ArrowLeft} style={{width: sm? "14px":"24px", marginRight: sm? "8px":"14px"}}/>
            <FlexBoxColumn style={{alignItems: "start"}}>
              <Text fontSize={["12px", "14px", "18px"]} style={{color: "#494949"}}>Återlämning</Text>
              <Text fontSize={["11px", "13px", "16px"]} style={{color: "#6C6C6C"}}>{endDate}</Text>
            </FlexBoxColumn>
          </FlexBoxRow>
          }
          <FlexBoxRow mx={"8px"}>
            <img src={PinsIcon} style={{width: sm? "14px":"24px", marginRight: sm? "8px":"14px"}}/>
            <FlexBoxColumn style={{alignItems: "start"}}>
              <Text fontSize={["12px", "14px", "18px"]} style={{color: "#494949"}}>Körsträcka</Text>
              <Text fontSize={["11px", "13px", "16px"]} style={{color: "#6C6C6C"}}>{distance !== ""? distance: 0} km</Text>
            </FlexBoxColumn>
          </FlexBoxRow>
          <FlexBoxRow mx={"8px"}>
            <img src={TransmissionIcon} style={{width: sm? "14px":"24px", marginRight: sm? "8px":"14px"}}/>
            <FlexBoxColumn style={{alignItems: "start"}}>
              <Text fontSize={["12px", "14px", "18px"]} style={{color: "#494949"}}>Växellåda</Text>
              <Text fontSize={["11px", "13px", "16px"]} style={{color: "#6C6C6C"}}>{transmission === "None"? "A & M": transmission}</Text>
            </FlexBoxColumn>
          </FlexBoxRow>
        </FlexBoxRow>
        </Box>

          <FlexBoxRow pb={[0, "16px","16px"]}>
            <Button style={{color: "#EC2B34", fontSize: sm? "15px":"18px", textTransform: "none"}} onClick={toggleIsOpenSearchMini}>
              Ändra
              <img src={DropdownArrow} style={{transform: "rotate(90deg)", width: "12px", marginLeft: "8px"}}/>
            </Button>


        </FlexBoxRow>
        </FlexBoxRow>
      </Container>
    </Box>
  )
}

export default SearchMini