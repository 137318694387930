import { Box, useMediaQuery } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";

import carTypeIcon from "../../assets/icons/cardIcons/car_type.svg";
import priceGroupIcon from "../../assets/icons/cardIcons/price_group.svg";
import seatsIcon from "../../assets/icons/cardIcons/seats.svg";
import transmissionIcon from "../../assets/icons/cardIcons/transmission.svg";
import fuelIcon from "../../assets/icons/cardIcons/fuel.svg";
import { Text, TextBold } from "../text/Text";
import { Context } from "../../context/Store";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import { calculatePrice, isHourPrice } from "../../utils/price";
import StyledButton from "../styledButton/StyledButton";

import Picture from "../picture/Picture";
import redInfoIcon from "../../assets/icons/info_red.svg";
import greyInfoIcon from "../../assets/icons/info_grey.svg";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import StyledPopover from "../styledPopover/StyledPopover";
import { formatPrice, formatPriceSEK } from "../../utils/formating";

import Linkify from "react-linkify";
import { dateToString, daysBetween, hoursBetween } from "../../utils/date";
import StyledInput from "../styledInput/StyledInput";
import PricePopUp from "../pricePopUp/PricePopUp";
import CustomAccordion from "../customAccordion/CustomAccordion";

const ExtraInfo = (info) => {
  const infoList = Object.keys(info).map((key) => {
    return { header: key, info: info[key] };
  });
  infoList.sort((a, b) => a.header.localeCompare(b.header));
  return (
    <FlexBoxColumn
      className="noscrollbar"
      alignItems="start"
      lineHeight="1.4"
      flexWrap="wrap"
      overflow="scroll"
      maxHeight="85vh"
      mt="-2px"
      width={["100%", "100%", "900px"]}
    >
      {infoList.map((text) => {
        return (
          <FlexBoxColumn
            alignItems="start"
            pt={["15px", "30px"]}
            px="15px"
            borderTop="1.5px solid #E0E0E0"
            flexGrow="1"
            mb="30px"
          >
            <TextBold>{text.header}</TextBold>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="_blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {text.info.map((paragraph) => {
                return <Text className="enterWrap">{paragraph}</Text>;
              })}
            </Linkify>
          </FlexBoxColumn>
        );
      })}
    </FlexBoxColumn>
  );
};

const InfoText = ({ icon, text }) => {
  return (
    <FlexBoxRow mb={1} justifyContent={["center", "center", "left"]}>
      <img
        src={icon}
        alt=""
        style={{ width: "20px", height: "19px", marginRight: "10px" }}
      />
      <Text mr={[0, 0, 1]}>{text}</Text>
    </FlexBoxRow>
  );
};

const BookingInfoCardVertical = ({
  buttonText,
  car,
  bookingTime,
  onSelect,
  setPriceInfo,
  loading,
  hidePrice,
  buttonFontSize,
  startDate,
  endDate,
  priceInfo,
  changeDistance,
  distance,
  totalPrice,
  additionList,
  damageReduction,
}) => {
  const { modell, priceGroup, seats, fuel, license, info, moreInfo, agelimit } =
    car;
  const { priceLists, setPopUp } = useContext(Context);
  const [, setPrice] = useState(0);
  const [, setPerTimePeriod] = useState("");
  const [priceData, setPriceData] = useState(null);
  const hours = hoursBetween(startDate, endDate);
  const days = daysBetween(startDate, endDate);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [normalPrice, setNormalPrice] = useState(0);
  const transmission = useMemo(() => {
    let transmissions = [];
    for (const carItem of car.availableCars) {
      if (carItem.transmission === "Automat" && !transmissions.includes("A")) {
        transmissions.push("A");
      }
      if (carItem.transmission === "Manuell" && !transmissions.includes("M")) {
        transmissions.push("M");
      }
    }
    transmissions.sort();
    return transmissions.join(" eller ");
  }, [car.availableCars]);
  const towbar = useMemo(() => {
    for (const carItem of car.availableCars) {
      if (carItem.towbar === true) {
        return true;
      }
    }
    return false;
  }, [car]);

  const hasInfo =
    moreInfo &&
    Object.keys(moreInfo).length !== 0 &&
    moreInfo.constructor === Object;
  const showInfo = () => {
    setPopUp(ExtraInfo(moreInfo));
  };

  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!priceLists || hidePrice) {
      return;
    }

    let time = bookingTime;
    if (!bookingTime) {
      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 1);
      const distance = 0;
      time = { startDate, endDate, distance };
    }
    const { startDate, endDate, distance } = time;

    const totalTime = endDate - startDate;
    const hourToMs = (hour) => {
      return hour * 60 * 60 * 1000;
    };

    const days = Math.ceil(totalTime / hourToMs(24));
    const hours = Math.ceil(totalTime / hourToMs(1));

    calculatePrice(
      startDate,
      endDate,
      distance,
      priceLists,
      car.debitedCargroup,
      car.campaign
    )
      .then((price) => {
        if (price.base.type === "T04") {
          setPerTimePeriod("för 4 timmar");
        } else {
          setPerTimePeriod("per dag");
        }
        if (car.campaign) {
          calculatePrice(
            startDate,
            endDate,
            distance,
            priceLists,
            car.debitedCargroup,
            null
          ).then((norm) => {
            setNormalPrice(norm.total.customer);
          });
        }

        const pricePerDay = Math.ceil(price.total.customer / days);
        setPriceData({ ...price, days, hours, damageReduction, totalPrice });
        setPrice(price.total.customer);
        if (setPriceInfo) {
          setPriceInfo({ ...price, days, hours, pricePerDay });
        }
      })
      .catch();
  }, [setPriceInfo, priceLists, bookingTime, priceGroup]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexBoxColumn
      bgcolor={"#FFF"}
      border={"1.25px solid #F2F2F2"}
      borderRadius={5}
      justifyContent={["flex-start", "flex-start", "space-around"]}
      alignItems="center"
    >
      <Picture
        name={car.name}
        carclass={car.carClass}
        width={["100%", "100%", "100%"]}
        minWidth={["100%", "100%", "100%"]}
        border={["5px 5px 0px 0px", "5px 5px 0px 0px", "5px 5px 0px 0px"]}
      />
      <FlexBoxColumn
        display="flex"
        flexDirection="column"
        ml={["0px", "0px", "0px"]}
        width={["100%", "100%", "auto"]}
        pb={"8px"}
        style={{ margin: "26px" }}
      >
        <TextBold
          textAlign={["center", "center", "center"]}
          fontSize={["26px", "26px", "30px"]}
          mb={["25px", "25px", "8px"]}
          mt={["25px", "25px", "0px"]}
        >
          {modell}
        </TextBold>
        <Text
          component="span"
          fontSize={["18px", "18px", "20px"]}
          style={{ whiteSpace: "pre" }}
          mb={"0px"}
        >
          eller liknande
        </Text>
      </FlexBoxColumn>

      {sm ? (
        <>
          <Box px={3} pb={3}>
            <CustomAccordion
              title={"Detaljer"}
              backgroundColor={"#FFF"}
              onChange={() => setIsDetailsOpen(!isDetailsOpen)}
            >
              <FlexBoxColumn pt={[2, 2, 0]}>
                <FlexBoxColumn
                  style={{
                    borderBottom: "1.5px solid #E0E0E0",
                    boxSizing: "border-box",
                  }}
                  pb={"20px"}
                  mb={["20px", "20px", "30px"]}
                  width={["100%", "100%", "80%"]}
                >
                  <FlexBoxRow
                    flexWrap="wrap"
                    justifyContent={"center"}
                    fontSize={[14, 14, 16]}
                    width={["auto", "auto", "auto"]}
                    mx={["auto", "auto", "0px"]}
                    textAlign={["center", "center", "center"]}
                  >
                    {license && (
                      <Box
                        mx={["10px", "10px", "10px"]}
                        width={["auto", "auto", "auto"]}
                      >
                        <InfoText icon={priceGroupIcon} text={license} />
                      </Box>
                    )}
                    {priceGroup && (
                      <Box
                        mx={["10px", "10px", "10px"]}
                        width={["auto", "auto", "auto"]}
                      >
                        <InfoText icon={carTypeIcon} text={priceGroup} />
                      </Box>
                    )}

                    {seats && (
                      <Box
                        mx={["10px", "10px", "10px"]}
                        width={["auto", "auto", "auto"]}
                      >
                        <InfoText icon={seatsIcon} text={seats} />
                      </Box>
                    )}
                    {transmission && (
                      <Box
                        mx={["10px", "10px", "10px"]}
                        width={["auto", "auto", "auto"]}
                      >
                        <InfoText icon={transmissionIcon} text={transmission} />
                      </Box>
                    )}
                    {fuel && (
                      <Box
                        mx={["10px", "10px", "10px"]}
                        width={["auto", "auto", "auto"]}
                      >
                        <InfoText icon={fuelIcon} text={fuel} />
                      </Box>
                    )}
                    {towbar && (
                      <Box
                        mx={["10px", "10px", "10px"]}
                        width={["auto", "auto", "auto"]}
                      >
                        <InfoText icon={carTypeIcon} text={"Dragkrok"} />
                      </Box>
                    )}
                  </FlexBoxRow>
                  {info &&
                    info.map((information) => (
                      <Text
                        width="100%"
                        textAlign={["center", "center", "center"]}
                        key={information}
                        mt={"0px"}
                      >
                        {information}
                      </Text>
                    ))}
                  {agelimit !== "" && agelimit !== undefined && (
                    <Text width="100%" textAlign={["center", "center", "left"]}>
                      Minimiålder: {agelimit} år
                    </Text>
                  )}
                </FlexBoxColumn>

                <Box width={"100%"}>
                  <FlexBoxColumn
                    justifyContent="start"
                    alignItems={["center", "center", "start"]}
                    fontSize="18px"
                    textAlign="left"
                    width={"auto"}
                    px={"30px"}
                  >
                    <Box width="auto" pb={4}>
                      <TextBold mb={1} textAlign={["center", "center", "left"]}>
                        Hyresperiod
                      </TextBold>
                      <Text
                        fontSize={"16px"}
                        textAlign={["center", "center", "left"]}
                      >
                        {priceInfo && isHourPrice(priceInfo)
                          ? hours + (hours > 1 ? " timmar" : " timme")
                          : days + " dygn"}
                      </Text>
                    </Box>
                    <Box width={"auto"} pb={4}>
                      <StyledPopover
                        popover={
                          <FlexBoxColumn
                            alignItems="flex-start"
                            fontSize={"16px"}
                            padding={"8px"}
                          >
                            <TextBold>Folkes Biluthyrning</TextBold>
                            <TextBold>Sellerigatan 5, 224 78, Lund</TextBold>
                            <Text>Hämtas endast under öppettider</Text>
                          </FlexBoxColumn>
                        }
                      >
                        <FlexBoxRow
                          justifyContent={["center", "center", "start"]}
                          alignItems={["center", "center", "start"]}
                          mb={1}
                        >
                          <TextBold
                            mr={"5px"}
                            textAlign={["center", "center", "left"]}
                          >
                            Upphämtning
                          </TextBold>
                          <img src={redInfoIcon} alt="" />
                        </FlexBoxRow>
                      </StyledPopover>
                      <Text
                        fontSize={"16px"}
                        textAlign={["center", "center", "left"]}
                      >
                        {dateToString(startDate)}
                      </Text>
                    </Box>
                    <Box width={"auto"} pb={4}>
                      <StyledPopover popover={ReturnInfo}>
                        <FlexBoxRow
                          justifyContent={["center", "center", "start"]}
                          alignItems={["center", "center", "start"]}
                          mb={1}
                        >
                          <TextBold
                            textAlign={["center", "center", "left"]}
                            mr={"5px"}
                          >
                            Återlämning
                          </TextBold>
                          <img src={redInfoIcon} alt="" />
                        </FlexBoxRow>
                      </StyledPopover>
                      <Text
                        fontSize={"16px"}
                        textAlign={["center", "center", "left"]}
                      >
                        {dateToString(endDate)}
                      </Text>
                    </Box>
                    <FlexBoxColumn
                      pb={4}
                      width={"auto"}
                      alignItems={["center", "center", "start"]}
                    >
                      <TextBold mb={1} textAlign={["center", "center", "left"]}>
                        Planerad körsträcka *
                      </TextBold>
                      <DistanceInput
                        onChange={changeDistance}
                        initialState={distance}
                      />
                    </FlexBoxColumn>
                  </FlexBoxColumn>
                </Box>
                <FlexBoxColumn width={"100%"} pb={6}>
                  <FlexBoxColumn
                    width={"auto"}
                    mb={["20px", "20px", 0]}
                    justifyContent={"center"}
                  >
                    <FlexBoxRow mb={4}>
                      <Box>
                        {car.campaign && normalPrice !== totalPrice && (
                          <Box position={"relative"}>
                            <Box
                              width={"100%"}
                              height="5px"
                              bgcolor={"black"}
                              position="absolute"
                              top="40%"
                              left="0%"
                              transform="translateY(-50%)"
                              style={{
                                transform: "rotate(8deg)",
                              }}
                            />
                            <TextBold fontSize={30} color={"#808080"} mr="5px">
                              {formatPriceSEK(normalPrice)}
                            </TextBold>
                          </Box>
                        )}

                        <TextBold fontSize={30} color={"#EC2B34"} mr="5px">
                          {formatPriceSEK(totalPrice)}
                        </TextBold>
                      </Box>

                      <StyledPopover
                        popover={PricePopUp(priceData, additionList)}
                      >
                        <img src={greyInfoIcon} alt="" />
                      </StyledPopover>
                    </FlexBoxRow>
                    <StyledButton
                      width="164px"
                      height="44px"
                      loading={loading}
                      onClick={() => onSelect(car)}
                      variant={"outlined"}
                    >
                      <TextBold fontSize={buttonFontSize || "18px"}>
                        {buttonText ?? "Ändra"}{" "}
                      </TextBold>
                    </StyledButton>

                    {hasInfo && (
                      <FlexBoxRow
                        onClick={showInfo}
                        mt="10px"
                        style={{ cursor: "pointer" }}
                      >
                        <img src={redInfoIcon} alt="" />
                        <Text style={{ textDecoration: "underline" }} ml="5px">
                          Mer info
                        </Text>
                      </FlexBoxRow>
                    )}
                  </FlexBoxColumn>
                </FlexBoxColumn>
              </FlexBoxColumn>
            </CustomAccordion>
          </Box>
        </>
      ) : (
        <FlexBoxColumn pt={[2, 2, 0]}>
          <FlexBoxColumn
            style={{
              borderBottom: "1.5px solid #E0E0E0",
              boxSizing: "border-box",
            }}
            pb={"20px"}
            mb={["20px", "20px", "30px"]}
            width={["100%", "100%", "80%"]}
          >
            <FlexBoxRow
              flexWrap="wrap"
              justifyContent={"center"}
              fontSize={[14, 14, 16]}
              width={["auto", "auto", "auto"]}
              mx={["auto", "auto", "0px"]}
              textAlign={["center", "center", "center"]}
            >
              {license && (
                <Box
                  mx={["10px", "10px", "10px"]}
                  width={["auto", "auto", "auto"]}
                >
                  <InfoText icon={priceGroupIcon} text={license} />
                </Box>
              )}
              {priceGroup && (
                <Box
                  mx={["10px", "10px", "10px"]}
                  width={["auto", "auto", "auto"]}
                >
                  <InfoText icon={carTypeIcon} text={priceGroup} />
                </Box>
              )}

              {seats && (
                <Box
                  mx={["10px", "10px", "10px"]}
                  width={["auto", "auto", "auto"]}
                >
                  <InfoText icon={seatsIcon} text={seats} />
                </Box>
              )}
              {transmission && (
                <Box
                  mx={["10px", "10px", "10px"]}
                  width={["auto", "auto", "auto"]}
                >
                  <InfoText icon={transmissionIcon} text={transmission} />
                </Box>
              )}
              {fuel && (
                <Box
                  mx={["10px", "10px", "10px"]}
                  width={["auto", "auto", "auto"]}
                >
                  <InfoText icon={fuelIcon} text={fuel} />
                </Box>
              )}
              {towbar && (
                <Box
                  mx={["10px", "10px", "10px"]}
                  width={["auto", "auto", "auto"]}
                >
                  <InfoText icon={carTypeIcon} text={"Dragkrok"} />
                </Box>
              )}
            </FlexBoxRow>
            {info &&
              info.map((information) => (
                <Text
                  width="100%"
                  textAlign={["center", "center", "center"]}
                  key={information}
                  mt={"0px"}
                >
                  {information}
                </Text>
              ))}
            {agelimit !== "" && agelimit !== undefined && (
              <Text width="100%" textAlign={["center", "center", "left"]}>
                Minimiålder: {agelimit} år
              </Text>
            )}
          </FlexBoxColumn>

          <Box width={"100%"}>
            <FlexBoxColumn
              justifyContent="start"
              alignItems={["center", "center", "start"]}
              fontSize="18px"
              textAlign="left"
              width={"auto"}
              px={"30px"}
            >
              <Box width="auto" pb={4}>
                <TextBold mb={1} textAlign={["center", "center", "left"]}>
                  Hyresperiod
                </TextBold>
                <Text
                  fontSize={"16px"}
                  textAlign={["center", "center", "left"]}
                >
                  {priceInfo && isHourPrice(priceInfo)
                    ? hours + (hours > 1 ? " timmar" : " timme")
                    : days + " dygn"}
                </Text>
              </Box>
              <Box width={"auto"} pb={4}>
                <StyledPopover
                  popover={
                    <FlexBoxColumn
                      alignItems="flex-start"
                      padding={"8px"}
                      fontSize={"16px"}
                    >
                      <TextBold>Folkes Biluthyrning</TextBold>
                      <TextBold>Sellerigatan 5, 224 78, Lund</TextBold>
                      <Text>Hämtas endast under öppettider</Text>
                    </FlexBoxColumn>
                  }
                >
                  <FlexBoxRow
                    justifyContent={["center", "center", "start"]}
                    alignItems={["center", "center", "start"]}
                    mb={1}
                  >
                    <TextBold
                      mr={"5px"}
                      textAlign={["center", "center", "left"]}
                    >
                      Upphämtning
                    </TextBold>
                    <img src={redInfoIcon} alt="" />
                  </FlexBoxRow>
                </StyledPopover>
                <Text
                  fontSize={"16px"}
                  textAlign={["center", "center", "left"]}
                >
                  {dateToString(startDate)}
                </Text>
              </Box>
              <Box width={"auto"} pb={4}>
                <StyledPopover popover={ReturnInfo}>
                  <FlexBoxRow
                    justifyContent={["center", "center", "start"]}
                    alignItems={["center", "center", "start"]}
                    mb={1}
                  >
                    <TextBold
                      textAlign={["center", "center", "left"]}
                      mr={"5px"}
                    >
                      Återlämning
                    </TextBold>
                    <img src={redInfoIcon} alt="" />
                  </FlexBoxRow>
                </StyledPopover>
                <Text
                  fontSize={"16px"}
                  textAlign={["center", "center", "left"]}
                >
                  {dateToString(endDate)}
                </Text>
              </Box>
              <FlexBoxColumn
                pb={4}
                width={"auto"}
                alignItems={["center", "center", "start"]}
              >
                <TextBold mb={1} textAlign={["center", "center", "left"]}>
                  Planerad körsträcka *
                </TextBold>
                <DistanceInput
                  onChange={changeDistance}
                  initialState={distance}
                />
              </FlexBoxColumn>
            </FlexBoxColumn>
          </Box>
          <FlexBoxColumn width={"100%"} pb={6}>
            <FlexBoxColumn
              width={"auto"}
              mb={["20px", "20px", 0]}
              justifyContent={"center"}
            >
              <FlexBoxRow mb={4}>
                <Box>
                  {car.campaign && normalPrice !== totalPrice && (
                    <Box position={"relative"}>
                      <Box
                        width={"100%"}
                        height="5px"
                        bgcolor={"black"}
                        position="absolute"
                        top="40%"
                        left="0%"
                        transform="translateY(-50%)"
                        style={{
                          transform: "rotate(8deg)",
                        }}
                      />
                      <TextBold fontSize={30} color={"#808080"} mr="5px">
                        {formatPriceSEK(normalPrice)}
                      </TextBold>
                    </Box>
                  )}

                  <TextBold fontSize={30} color={"#EC2B34"} mr="5px">
                    {formatPriceSEK(totalPrice)}
                  </TextBold>
                </Box>
                <StyledPopover popover={PricePopUp(priceData, additionList)}>
                  <img src={greyInfoIcon} alt="" />
                </StyledPopover>
              </FlexBoxRow>
              <StyledButton
                width="164px"
                height="44px"
                loading={loading}
                onClick={() => onSelect(car)}
                variant={"outlined"}
              >
                <TextBold fontSize={buttonFontSize || "18px"}>
                  {buttonText ?? "Ändra"}{" "}
                </TextBold>
              </StyledButton>

              {hasInfo && (
                <FlexBoxRow
                  onClick={showInfo}
                  mt="10px"
                  style={{ cursor: "pointer" }}
                >
                  <img src={redInfoIcon} alt="" />
                  <Text style={{ textDecoration: "underline" }} ml="5px">
                    Mer info
                  </Text>
                </FlexBoxRow>
              )}
            </FlexBoxColumn>
          </FlexBoxColumn>
        </FlexBoxColumn>
      )}
    </FlexBoxColumn>
  );
};
export default BookingInfoCardVertical;

const DistanceInput = ({ onChange, initialState }) => {
  const [distance, setDistance] = useState(initialState);

  const onChangeDistance = (value) => {
    if (
      (value === "" || (!isNaN(value) && !isNaN(parseFloat(value)))) &&
      !value.includes(".") &&
      value.length < 6
    ) {
      setDistance(value);
      onChange(value);
    }
  };

  return (
    <StyledInput
      width="90px"
      borderRadius={5}
      height={"43px"}
      bgcolor="#EEE"
      value={distance}
      onChange={onChangeDistance}
      postfix={"km"}
      pr={"10px"}
    />
  );
};

const ReturnInfo = (
  <FlexBoxColumn
    className="noscrollbar"
    alignItems="flex-start"
    margin="16px"
    fontSize="16px"
    overflow="scroll"
    width="300px"
  >
    <TextBold width="100%" className="wordBreak">
      Återlämnas
      <br />
      Folkes Biluthyrning 
      <br />
      Sellerigatan 5, 224 78, Lund
    </TextBold>
    <Text width="100%" className="wordBreak">
      Återlämnas dygnet runt.
      <br />
      <br />
      När kontoret är stängt används vårt nyckelinkast. Nyckelinkastet hittar ni
      till vänster på byggnaden, där gallersläpen står parkerade.
      <br />
      Om grinden till fordonen är stängd, parkeras fordonen på lämplig plats
      utanför grindarna.
      <br />
      <br />
      Fordonet återlämnas med full tank, tankning sker vid närmsta
      bränslestation innan återlämning.
      <br />
      Alternativt kan du lämna tankningen till oss. Du betalar för bränslet du
      använt, samt en serviceavgift på 100 kr.
    </Text>
  </FlexBoxColumn>
);
