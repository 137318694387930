import { Box, Container } from "@material-ui/core";
import React, { useEffect } from "react";
import { Text, TextBold } from "../../text/Text";

const AboutUs = () => {
  useEffect(() => {
    document.title = "Om oss";
  }, []);
  return (
    <Container maxWidth="md">
      <TextBold component="h1" fontSize={["24px", "24px", "35px"]} mt="30px">
        Om Folkes Biluthyrning
      </TextBold>

      <Box
        display="flex"
        flexDirection={["column", "row", "row"]}
        bgcolor={"#FFF"}
        border={"1.25px solid #F2F2F2"}
        borderRadius={5}
        alignItems="flex-start"
        maxWidth="800px"
        mx="auto"
        my="40px"
      >
        <Box textAlign="left" m={["30px", "50px"]} order={[1, -1, -1]}>
          <TextBold fontSize="18px">
            Lång erfarenhet ger servicekänsla – När du hyr hos oss får du alltid
            fräscha bilar och släp till låga priser. <br />
            <br />
          </TextBold>
          <Text fontSize="16px">
            Vår affärsidé är enkel. Vi skall vara ett komplett
            biluthyrningsföretag där vi kan erbjuda allt från minsta personbil
            till stora flyttbilar. <br />
            <br />
            För att se till att våra personbilar alltid är utrustade med den
            senaste teknologin vad gäller säkerhet och miljö, byter vi ut dem
            var 6-12:e månad. <br />
            <br />
            Om du är ute efter att hyra en bil för en längre period är det bara
            att kontakta oss för ett prisförslag här. <br />
            <br />
            Vi ingår i den rikstäckande kedjan Hyrbilen.
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutUs;
