import { Box, Container, Hidden } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import FlexBoxColumn from "../../flexBox/FlexBoxColumn";
import FlexBoxRow from "../../flexBox/FlexBoxRow";
import { TextBold, Text } from "../../text/Text";

import penIcon from "../../../assets/icons/pen.svg";
import StyledButton from "../../styledButton/StyledButton";
import Picture from "../../picture/Picture";
import { Context } from "../../../context/Store";
import { functions } from "../../../database/firebase";
import { dateToString } from "../../../utils/date";
import { Redirect, useHistory } from "react-router";
import { ReactComponent as LoadingIcon } from "../../../assets/icons/loading.svg";

const AcountInfoCard = ({ customerInfo, updateProfileInfo }) => (
  <Box
    bgcolor={"#FFF"}
    border={"1.25px solid #F2F2F2"}
    borderRadius={5}
    width="250px"
    textAlign="left"
    p={["15px", "30px"]}
    lineHeight="1.8"
    fontSize="16px"
  >
    <TextBold fontSize="18px">Kontoinnehavare</TextBold>
    <Box borderTop="1.5px solid #E5E5E5" />
    <Text>
      {customerInfo.firstName} {customerInfo.lastName}
    </Text>
    <Text>{customerInfo.personNumber}</Text>
    <FlexBoxRow justifyContent="space-between" mt="20px">
      <TextBold fontSize="18px">Kontaktuppgifter</TextBold>
      <img
        src={penIcon}
        alt=""
        style={{ cursor: "pointer" }}
        onClick={updateProfileInfo}
      />
    </FlexBoxRow>
    <Box borderTop=" 1.5px solid #E5E5E5" />
    <Text>{customerInfo.email}</Text>
    <Text>{customerInfo.phoneNumber}</Text>
    <Text>
      {customerInfo.address}
      {customerInfo.postNumber || customerInfo.city ? ", " : ""}
      {customerInfo.postNumber} {customerInfo.city}
    </Text>
  </Box>
);

const bookingCard = ({ booking, buttons, selectCar }) => (
  <FlexBoxRow
    bgcolor={"#FFF"}
    border={"1.25px solid #F2F2F2"}
    borderRadius={5}
    width={["300px", "520px"]}
    textAlign="left"
    p={["15px", "15px", "25px"]}
    lineHeight="1.8"
    justifyContent="space-between"
    fontSize="16px"
    key={booking.id}
    mx={["auto", "auto", "0px"]}
    my="8px"
  >
    <Picture
      name={booking.car.name}
      carClass={booking.car.carClass}
      width="40%"
    />

    <Hidden xsDown>
      <Box>
        <TextBold>{booking.car.modell}</TextBold>
        <Text>{dateToString(booking.from)} -</Text>
        <Text>{dateToString(booking.to)}</Text>
      </Box>
      <FlexBoxColumn>
        {buttons.map((button) => (
          <Box my="10px" fontSize="16px">
            <StyledButton
              color="secondary"
              width="140px"
              height="40px"
              padding="0px"
              fullWidth
              onClick={() => button.onClick(booking)}
            >
              {button.text}
            </StyledButton>
          </Box>
        ))}
      </FlexBoxColumn>
      {buttons.length === 0 && <Box width="140px"></Box>}
    </Hidden>
    <Hidden smUp>
      <Box>
        <TextBold
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => selectCar(booking)}
        >
          {booking.car.modell}
        </TextBold>
        <Text>{dateToString(booking.from)} -</Text>
        <Text>{dateToString(booking.to)}</Text>
      </Box>
    </Hidden>
  </FlexBoxRow>
);

const carCard = ({ booking, buttons, buttonText, onClick }) => (
  <Box
    bgcolor={"#FFF"}
    border={"1.25px solid #F2F2F2"}
    borderRadius={5}
    width="100%"
    maxWidth="330px"
    textAlign="left"
    lineHeight="1.8"
    justifyContent="space-between"
    fontSize="16px"
  >
    <Picture
      name={booking.car.name}
      carClass={booking.car.carClass}
      width="100%"
    />
    <Box p="15px" textAlign="center">
      <TextBold fontSize="26px">{booking.car.modell}</TextBold>
      <Box borderTop="1.5px solid #E5E5E5" />
      <TextBold fontSize="18px">Upphämtning</TextBold>
      <Text fontSize="16px">{dateToString(booking.from)} </Text>
      <TextBold fontSize="18px">Avlämning</TextBold>
      <Text fontSize="16px">{dateToString(booking.to)}</Text>
      {buttons.map((button) => (
        <Box my="10px" width="100%">
          <StyledButton
            color="secondary"
            height="40px"
            fullWidth
            onClick={() => button.onClick(booking)}
          >
            {button.text}
          </StyledButton>
        </Box>
      ))}
    </Box>
  </Box>
);

const Profile = () => {
  useEffect(() => {
    document.title = "Mina sidor";
  }, []);

  const [bookingView, setBookingView] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const [futureBookings, setFutureBookings] = useState([]);
  const [activeBookings, setActiveBookings] = useState([]);
  const [previosBookings, setpreviosBookings] = useState([]);
  const [loadingBookings, setLoadingBookings] = useState(false);

  const { setPopUp, customerInfo, user, setChangeBooking } =
    useContext(Context);
  const history = useHistory();

  const changeBookingView = (bookingView) => {
    setSelectedBooking(null);
    setBookingView(bookingView);
  };

  const updateProfileInfo = () => {
    history.push("/profil/andra-uppgifter");
  };

  // Get bookings
  useEffect(() => {
    const fetchBookings = async () => {
      if (loadingBookings) return;
      setLoadingBookings(true);
      try {
        const getCustomersBooking = functions.httpsCallable(
          "booking-getCustomers"
        );
        let bookings = await getCustomersBooking({
          personNumber: customerInfo.personNumber,
        }).then((bookings) => bookings.data);
        console.log(bookings);
        bookings = bookings.map((booking) => ({
          ...booking,
          from: new Date(booking.from),
          to: new Date(booking.to),
        }));
        const newFutureBookings = [];
        const newActiveBookings = [];
        const newPreviosBookings = [];
        const now = new Date();
        for (const booking of bookings) {
          if (now < booking.from) {
            newFutureBookings.push(booking);
          } else if (now < booking.to) {
            newActiveBookings.push(booking);
          } else {
            newPreviosBookings.push(booking);
          }
        }
        setFutureBookings(newFutureBookings);
        setActiveBookings(newActiveBookings);
        setpreviosBookings(newPreviosBookings);
      } catch (error) {}
      setLoadingBookings(false);
    };

    if (customerInfo) {
      fetchBookings();
    }
  }, [customerInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const ConfirmPopUp = (onConfirm) => {
    const confirm = () => {
      setPopUp(null);
      onConfirm();
    };
    setPopUp(
      <Box>
        <Text my="20px">Är du säker på att du vill avboka</Text>
        <StyledButton onClick={confirm}>Avboka</StyledButton>
      </Box>
    );
  };
  const cancelCustomerBooking = async (booking) => {
    ConfirmPopUp(async () => {
      try {
        const cancelBooking = functions.httpsCallable("booking-cancel");
        await cancelBooking({ docId: booking.id });
        let newBookings = futureBookings.filter((b) => b.id !== booking.id);
        setFutureBookings(newBookings);
      } catch (error) {}
      setSelectedBooking(null);
    });
  };

  const changeBooking = async (bookingData) => {
    try {
      const loadBooking = functions.httpsCallable("booking-loadBooking");
      const booking = await loadBooking({ bookingId: bookingData.id }).then(
        (res) => res.data
      );
      console.log(booking);
      booking.bookingTime = {
        ...booking.bookingTime,
        startDate: new Date(booking.bookingTime.startDate),
        endDate: new Date(booking.bookingTime.endDate),
      };
      let additions = {
        pillows: 0,
        childSeats: 0,
        abroadPermit: "",
        towbar: false,
      };
      for (const addition of booking.additions) {
        if (addition.beskrivning === "Bilkudde") {
          additions.pillows++;
        } else if (addition.beskrivning === "Barnstolar") {
          additions.childSeats++;
        } else if (addition.beskrivning.startsWith("Utlandstillägg: ")) {
          additions.abroadPermit = addition.beskrivning.substr(16);
        } else if (addition.beskrivning === "Dragkrok") {
          additions.towbar = true;
        }
      }
      booking.additions = additions;
      setChangeBooking(booking);
      history.push("/profil/andra-bokning");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      {!user ? (
        <Redirect to="/profil/logga-in" />
      ) : (
        <Box mt="30px" mb={["60px", "90px"]}>
          <Hidden mdUp>
            <FlexBoxRow fontSize="22px" mb="25px" justifyContent="center">
              <TextBold
                color={bookingView ? "#767676" : "#000"}
                style={{ cursor: "pointer" }}
                onClick={() => changeBookingView(false)}
                mr="10px"
              >
                Kontouppgifter
              </TextBold>
              <TextBold
                color={bookingView ? "#000" : "#767676"}
                style={{ cursor: "pointer" }}
                onClick={() => changeBookingView(true)}
                ml="10px"
              >
                Bokningar
              </TextBold>
            </FlexBoxRow>
            <FlexBoxRow justifyContent="center">
              {!selectedBooking && bookingView && loadingBookings && (
                <Box mt="30px">
                  <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
                </Box>
              )}
              {!selectedBooking && bookingView && !loadingBookings && (
                <Box textAlign="left" fontSize="20px" width="100%">
                  {activeBookings.length === 0 &&
                    futureBookings.length === 0 &&
                    previosBookings.length === 0 && (
                      <Text textAlign="center">Inga bokningar</Text>
                    )}
                  {activeBookings.length !== 0 && (
                    <Text
                      width={["330px", "550px"]}
                      mx="auto"
                      borderBottom="2px solid #E5E5E5"
                      mb="20px"
                      pb="8px"
                    >
                      Pågående
                    </Text>
                  )}
                  {activeBookings.map((booking) => {
                    return bookingCard({
                      booking,
                      buttons: [],
                      selectCar: (booking) =>
                        setSelectedBooking({ booking: booking, buttons: [] }),
                    });
                  })}
                  {futureBookings.length !== 0 && (
                    <Text
                      width={["330px", "550px"]}
                      mx="auto"
                      mt="35px"
                      borderBottom="2px solid #E5E5E5"
                      pb="8px"
                      mb="20px"
                    >
                      Uppkommande
                    </Text>
                  )}
                  {futureBookings.map((booking) => {
                    return bookingCard({
                      booking,
                      buttons: [
                        { text: "Avboka", onClick: cancelCustomerBooking },
                        { text: "Ändra bokning", onClick: changeBooking },
                      ],
                      selectCar: (booking) =>
                        setSelectedBooking({
                          booking: booking,
                          buttons: [
                            { text: "Avboka", onClick: cancelCustomerBooking },
                            { text: "Ändra bokning", onClick: changeBooking },
                          ],
                        }),
                    });
                  })}
                  {previosBookings.length !== 0 && (
                    <Text
                      width={["330px", "550px"]}
                      mx="auto"
                      mt="35px"
                      borderBottom="2px solid #E5E5E5"
                      mb="20px"
                    >
                      Föregångna
                    </Text>
                  )}
                  {previosBookings.map((booking) => {
                    return bookingCard({
                      booking,
                      buttons: [],
                      selectCar: (booking) =>
                        setSelectedBooking({ booking: booking, buttons: [] }),
                    });
                  })}
                </Box>
              )}
              {!selectedBooking &&
                !bookingView &&
                AcountInfoCard({ customerInfo, updateProfileInfo })}
              {selectedBooking && carCard(selectedBooking)}
            </FlexBoxRow>
          </Hidden>
          <Hidden smDown>
            <FlexBoxRow
              justifyContent="center"
              alignItems="start"
              lineHeight="1.8"
              fontSize="22px"
            >
              <Box textAlign="left" mr="10px">
                <Text mb="8px">Kontouppgifter</Text>
                {AcountInfoCard({ customerInfo, updateProfileInfo })}
              </Box>
              {loadingBookings ? (
                <FlexBoxColumn width="600px" ml="10px" alignItems="start">
                  <Text mb="30px">Laddar bokningar</Text>
                  <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
                </FlexBoxColumn>
              ) : (
                <FlexBoxColumn width="600px" ml="10px" alignItems="start">
                  {activeBookings.length === 0 &&
                    futureBookings.length === 0 &&
                    previosBookings.length === 0 && <Text>Inga bokningar</Text>}
                  {activeBookings.length !== 0 && <Text>Pågående</Text>}
                  {activeBookings.map((booking) => {
                    return bookingCard({
                      booking,
                      buttons: [],
                    });
                  })}
                  {futureBookings.length !== 0 && <Text>Uppkommande</Text>}
                  {futureBookings.map((booking) => {
                    return bookingCard({
                      booking,
                      buttons: [
                        { text: "Avboka", onClick: cancelCustomerBooking },
                        { text: "Ändra bokning", onClick: changeBooking },
                      ],
                    });
                  })}
                  {previosBookings.length !== 0 && <Text>Föregångna</Text>}
                  {previosBookings.map((booking) => {
                    return bookingCard({
                      booking,
                      buttons: [],
                    });
                  })}
                </FlexBoxColumn>
              )}
            </FlexBoxRow>
          </Hidden>
        </Box>
      )}
    </Container>
  );
};

export default Profile;
