const currencyFormatter = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  currency: "SEK",
  maximumFractionDigits: 0,
});

export const formatPrice = (price) => {
  return currencyFormatter.format(Math.ceil(price));
};

export const formatPriceSEK = (price) => {
  const krPrice = formatPrice(price); // Price with kr postfix
  return krPrice.replace("kr", "SEK");
};
