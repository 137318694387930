import { Box, Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Context } from "../../../context/Store";
import { functions } from "../../../database/firebase";
import { carClassParam } from "../../../utils/Search";
import CarCardInfo from "../../carCardInfo/CarCardInfo";
import NoticeCard from "../../noticeCard/NoticeCard";
import { TextBold } from "../../text/Text";
import TimePicker from "./TimePicker";

const BookingTime = () => {
  useEffect(() => {
    document.title = "Välj tid";
  }, []);

  const { car, setBookingTime, setReservation, reservation } =
    useContext(Context);
  const [errorMessage, setErrorMessage] = useState("");
  const [madeBooking, setMadeBooking] = useState(false);
  // Local bookingTime only for carCardInfo price calculation
  const [time, setTime] = useState(null);
  const [loading, setLoading] = useState(false);

  // Unreserve car
  useEffect(() => {
    if (!madeBooking && reservation) {
      const resetReservation = functions.httpsCallable("cars-unreserve");
      try {
        resetReservation({ carId: reservation.carId });
        setReservation(null);
      } catch {}
    }
  }, [reservation, setReservation, madeBooking]);

  const history = useHistory();
  // Reserve car
  const selectTime = async ({
    startDate,
    endDate,
    distance,
    transmission,
    towbar,
  }) => {
    const reserveCar = functions.httpsCallable("cars-reserve");

    if (loading) return;
    setLoading(true);
    setErrorMessage("");
    try {
      if (transmission) {
        car.availableCars = car.availableCars.filter(
          (car) => car.transmission === transmission
        );
      }

      // TODO: Make sure that the car is available at the time specififed by the user

      const response = await reserveCar({
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
        cars: car.availableCars,
      }).then((res) => {
        return res.data;
      });

      if (response.available) {
        setMadeBooking(true);
        setReservation({
          until: new Date(response.until),
          carId: response.carId,
        });

        setBookingTime({
          carClass: car.carClass,
          startDate,
          endDate,
          distance,
          transmission,
          towbar,
        });
        history.push("/bokning/tillval");
      } else {
        setErrorMessage(
          "Bilen är uppbokad vid det tillfället, försök med en annan tid"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const changeBooking = () => {
    history.replace(`/sok/b=${carClassParam[car.carClass]}`);
  };

  if (!car) {
    return <Redirect to="/vara-fordon" />;
  }

  return (
    <Container maxWidth="md">
      <TextBold
        textAlign="left"
        fontSize={["24px", "24px", "35px"]}
        mt="30px"
        mb="40px"
      >
        Välj hyrtillfälle
      </TextBold>
      <CarCardInfo
        car={car}
        bookingTime={time}
        buttonText="Ändra val"
        onSelect={changeBooking}
      />
      <NoticeCard carClass={car.carClass} />
      <Box mt="30px" mb={["60px", "90px"]}>
        <TimePicker
          onSelect={selectTime}
          setTime={setTime}
          inputMode="numeric"
          car={car}
          loading={loading}
        />
        {errorMessage && (
          <TextBold fontSize="16px" color="red">
            {errorMessage}
          </TextBold>
        )}
      </Box>
    </Container>
  );
};

export default BookingTime;
