import React, { useContext, useState } from "react";
import {
  Box,
  Fade,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CarCardSimple from "../carCardSimple/CarCardSimple";
import { Context } from "../../context/Store";
import arrowIcon from "../../assets/icons/dropdown_arrow_black.svg";

const useStyles = makeStyles(() => ({
  arrow: {
    width: "32px",
    height: "32px",
    alignSelf: "center",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));

/**
 * Carousel for displaying list of CarCardSimple
 * @param cars - list of cars
 * @returns {JSX.Element}
 * @author Oscar Bennet
 */
const CarCarousel = ({ cars }) => {
  const { priceLists } = useContext(Context);
  const [startIndex, setStartIndex] = useState(0);
  const [show, setShow] = useState(true);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  //amount of cards visible in list, xs is mobile
  const visibleCards = xs ? 2 : 3;
  const fadeOutDuration = 100;
  const fadeInDuration = 300;

  const onRight = () => {
    setShow(false);
    //wait until fade out before loading in new cars
    setTimeout(() => {
      //restart if end of list
      if (cars.length <= startIndex + visibleCards) setStartIndex(0);
      //change start index the same amount as there are cars left in the list
      else if (cars.length - (startIndex + visibleCards) < visibleCards)
        setStartIndex(startIndex + ((cars.length - startIndex) % visibleCards));
      else setStartIndex(startIndex + visibleCards);
      setTimeout(() => setShow(true), fadeInDuration);
    }, fadeOutDuration);
  };

  const onLeft = () => {
    setShow(false);
    setTimeout(() => {
      if (startIndex <= 0) setStartIndex(cars.length - visibleCards);
      else if (startIndex < visibleCards)
        setStartIndex(startIndex - (startIndex % visibleCards));
      else setStartIndex(startIndex - visibleCards);
      setTimeout(() => setShow(true), fadeInDuration);
    }, fadeOutDuration);
  };

  /**
   * Fixed height for cards depending on screen width
   * @returns {number}
   */
  const cardHeight = () => {
    if (xs) return 340;
    else if (sm) return 360;
    else return 380;
  };

  return (
    <Box style={{ width: "100%", display: "flex", flexDirection: "row" }}>
      <img
        src={arrowIcon}
        style={{ transform: "rotate(90deg)", paddingRight: 16 }}
        className={useStyles().arrow}
        onClick={onLeft}
        alt=""
      />
      <Fade
        in={show}
        timeout={{ exit: fadeOutDuration, enter: fadeInDuration }}
        disableStrictModeCompat={true}
      >
        <Grid container spacing={3}>
          {cars !== undefined && cars.length > 0 ? (
            cars
              .slice(startIndex, startIndex + visibleCards)
              .map((car, index) => (
                <Grid xs={6} sm={4} md={4} item key={index}>
                  <CarCardSimple
                    car={car}
                    priceLists={priceLists}
                    height={cardHeight()}
                  />
                </Grid>
              ))
          ) : (
            <Box
              width={"100%"}
              height={"100%"}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography style={{ fontSize: 20, color: "red" }}>
                Inga bilar hittades
              </Typography>
            </Box>
          )}
        </Grid>
      </Fade>
      <img
        src={arrowIcon}
        style={{ transform: "rotate(-90deg)", paddingLeft: 16 }}
        className={useStyles().arrow}
        onClick={onRight}
        alt=""
      />
    </Box>
  );
};

export default CarCarousel;
