import { Box, makeStyles, MenuItem, Select } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "inherit",
    borderRadius: "3px",
    textAlign: "left",
    fontFamily: "Replica",
    "& .MuiSelect-select": {
      padding: "12px 0px 12px 10px",
    },
    display: "block",
    width: "100%",
    height: "100%",
  },
  small: {
    "& .MuiSelect-select": {
      padding: "3px 0px 3px 10px",
    },
  },
  medium: {
    "& .MuiSelect-select": {
      padding: "8px 0px 8px 10px",
    },
  },
  option: {
    fontFamily: "Replica",
  },
}));

const Dropdown = ({
  value,
  options,
  onChange,
  width,
  height,
  disabled,
  bgcolor,
  initValue,
  small,
  medium,
  borderRadius,
}) => {
  const classes = useStyles();
  return (
    <Box width={width} height={height ?? "25px"} bgcolor={bgcolor ?? "#F2F2F2"} borderRadius={borderRadius}>
      <Select
        disabled={disabled}
        className={`${classes.root} ${small ? classes.small : ""} ${medium ? classes.medium : ""}`}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        defaultValue={initValue !== undefined ? initValue : options[0].value}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((type, i) => (
          <MenuItem value={type.value} key={i} className={classes.option}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default Dropdown;
