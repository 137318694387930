import { Box, Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Context } from "../../../context/Store";
import FlexBoxRow from "../../flexBox/FlexBoxRow";
import LoginForm from "../../loginForm/LoginForm";
import { TextBold } from "../../text/Text";

const Login = () => {
  useEffect(() => {
    document.title = "Logga in";
  }, []);

  const { customerInfo, user, setCustomerInfo } = useContext(Context);
  const [passwordBeenReset, setPasswordBeenReset] = useState(false);
  const passwordReset = async () => {
    setPasswordBeenReset(true);
  };

  const submit = async (customerInfo) => {
    setCustomerInfo(customerInfo);
  };

  const history = useHistory();
  const goToRegisterPage = () => {
    history.push("/profil/registrera");
  };

  return (
    <Container maxWidth="md">
      {user && customerInfo ? (
        <Redirect to="/profil/mina-sidor" />
      ) : (
        <FlexBoxRow
          mt={["30px", "50px", "70px"]}
          mb={["60px", "90px"]}
          justifyContent="center"
        >
          {passwordBeenReset ? (
            <Box>
              <TextBold fontSize="24px">
                Du har nu fått en ett email med länk för att byta lösenord.
              </TextBold>
              <TextBold
                fontSize="24px"
                color="#c00"
                className="pointer underline"
                onClick={() => setPasswordBeenReset(false)}
              >
                Gå tillbaka till logga in
              </TextBold>
            </Box>
          ) : (
            <LoginForm
              submitForm={submit}
              registerInstead={goToRegisterPage}
              passwordReset={passwordReset}
            />
          )}
        </FlexBoxRow>
      )}
    </Container>
  );
};

export default Login;
