import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Datepicker from "../../datePicker/Datepicker";
import { TextBold } from "../../text/Text";
import Dropdown from "../../dropdown/Dropdown";
import { Context } from "../../../context/Store";
import StyledInput from "../../styledInput/StyledInput";
import StyledButton from "../../styledButton/StyledButton";
import {
  changeEndDate,
  changeStartDate,
  getNextOpenTime,
  isOpenDateFunc,
  isOpenTimeFunc,
} from "../../../utils/pickUpTime";
import FlexBoxColumn from "../../flexBox/FlexBoxColumn";

const InputLabel = ({ children }) => {
  return (
    <FlexBoxColumn
      alignItems="flex-start"
      justifyContent="flex-end"
      height={["auto", "auto", "44px"]}
    >
      <TextBold
        fontSize={["16px", "16px", "18px"]}
        mb={["13px", "13px", "6px"]}
        textAlign={["center", "center", "left"]}
      >
        {children}
      </TextBold>
    </FlexBoxColumn>
  );
};

/**
 * Search bar
 * @author Erik Jonasson
 */
const TimePicker = ({ onSelect, car, setTime, loading }) => {
  const bgcolor = ["#FFF"];
  const color = ["#000"];
  const inputBgcolor = ["#F2F2F2"];

  const transmissionTypes = useMemo(() => {
    let transmissions = [];
    for (const carItem of car.availableCars) {
      if (
        carItem.transmission === "Manuell" &&
        !transmissions.includes("Manuell")
      ) {
        transmissions.push(carItem.transmission);
      }
      if (
        carItem.transmission === "Automat" &&
        !transmissions.includes("Automat")
      ) {
        transmissions.push(carItem.transmission);
      }
    }
    return transmissions.map((transmission) => ({
      value: transmission,
      label: transmission,
    }));
  }, [car]);
  const towbarTypes = useMemo(() => {
    let towbars = [];
    for (const carItem of car.availableCars) {
      if (carItem.towbar === true && !towbars.includes(true)) {
        towbars.push(true);
      }
      if (carItem.transmission !== true && !towbars.includes(false)) {
        towbars.push(false);
      }
    }
    return towbars.map((towbar) => ({
      value: towbar,
      label: towbar ? "Ja" : "Nej",
    }));
  }, [car]);

  const [transmission, setTransmission] = useState(transmissionTypes[0]?.value);
  const [distance, setDistance] = useState("30");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [towbar, setTowbar] = useState(false);

  const { openHours } = useContext(Context);
  useEffect(() => {
    let firstDate = getNextOpenTime(new Date(), openHours);
    setStartDate(firstDate);
    let nextDate = new Date(firstDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setEndDate(nextDate);
  }, [openHours]);

  useEffect(() => {
    setTime({
      carClass: car.carClass,
      startDate,
      endDate,
      distance,
      transmission,
      towbar,
    });
  }, [startDate, endDate, distance, transmission, towbar, car, setTime]);

  const changeDistance = (value) => {
    if (
      (value === "" || (!isNaN(value) && !isNaN(parseFloat(value)))) &&
      !value.includes(".") &&
      value.length < 5
    ) {
      setDistance(value);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={["center", "center", "flex-start"]}
      flexWrap={["wrap", "wrap", "nowrap"]}
      flexDirection={["row", "row", "row"]}
      bgcolor={bgcolor}
      color={color}
      style={{
        filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))",
      }}
      pl={["25px", "25px", "20px"]}
      pr={["25px", "25px", "0px"]}
      py={["30px", "30px", "30px"]}
      borderRadius="5px"
    >
      <Box width={["100%", "47.5%", "175px"]} mr={[0, "5%", "2.5%"]}>
        <InputLabel>Tid för upphämtning</InputLabel>
        <Box bgcolor={inputBgcolor}>
          <Datepicker
            style={{ width: "auto" }}
            selected={startDate}
            filterTime={isOpenTimeFunc(openHours)}
            filterDate={isOpenDateFunc(openHours)}
            handleSelect={(date) => changeStartDate(date.value, openHours, endDate, setStartDate, setEndDate)}
            showTimeSelect
          />
        </Box>
      </Box>
      <Box
        width={["100%", "47.5%", "175px"]}
        mt={["15px", "0px", "0px"]}
        mr={[0, 0, "2.5%"]}
      >
        <InputLabel>Tid för återlämning</InputLabel>
        <Box bgcolor={inputBgcolor}>
          <Datepicker
            style={{ width: "auto" }}
            selected={endDate}
            minDate={startDate}
            handleSelect={(date) => changeEndDate(date.value, startDate, setEndDate)}
            showTimeSelect
          />
        </Box>
      </Box>
      <Box
        width={["100%", "47.5%", "150px"]}
        mr={[0, "5%", "2.5%"]}
        mt={["15px", "15px", "0px"]}
      >
        <InputLabel>Planerad körsträcka</InputLabel>
        <Box bgcolor={inputBgcolor}>
          <StyledInput
            height="43px"
            value={distance}
            onChange={changeDistance}
            postfix={"km"}
            pr={"10px"}
          ></StyledInput>
        </Box>
      </Box>
      {transmissionTypes.length > 0 && (
        <Box
          width={["100%", "47.5%", "auto"]}
          mt={["15px", "15px", "0px"]}
          mr={[0, 0, "2.5%"]}
        >
          <InputLabel>Växellåda</InputLabel>
          <Box bgcolor={inputBgcolor}>
            <Dropdown
              width={["100%", "100%", "100px"]}
              height={"43px"}
              value={transmission}
              onChange={(value) => setTransmission(value)}
              options={transmissionTypes}
              bgcolor={inputBgcolor}
            />
          </Box>
        </Box>
      )}
      <Box
        width={["100%", "47.5%", "100px"]}
        mt={["15px", "15px", "0px"]}
        mr={[0, 0, "2.5%"]}
      >
        <InputLabel>Dragkrok</InputLabel>
        <Box bgcolor={inputBgcolor}>
          <Dropdown
            height={"43px"}
            value={towbar}
            onChange={(value) => setTowbar(value)}
            options={towbarTypes}
            bgcolor={inputBgcolor}
          />
        </Box>
      </Box>
      <Box
        width={["100%", "100%", "150px"]}
        m={["30px auto 0px", "30px auto 0px", "auto 10px 0 auto"]}
      >
        <StyledButton
          height="44px"
          loading={loading}
          onClick={() =>
            onSelect({ startDate, endDate, distance, transmission, towbar })
          }
        >
          Fortsätt
        </StyledButton>
      </Box>
    </Box>
  );
};

export default TimePicker;
