import { getPriceBase, isHourPrice, tax } from "../../utils/price";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import { Text, TextBold } from "../text/Text";
import { Box } from "@material-ui/core";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import { formatPrice } from "../../utils/formating";
import React, { useEffect } from "react";
import { getAdditionCost } from "../pages/bookingAdditions/bookingAdditionsUtil";

const PricePopUp = (price, additions, campaignInc = null) => {
  if (!price) {
    return <div></div>;
  }

  const priceBase = getPriceBase(price);
  const cdwName = ["Basförsäkring", "Självriskreducering"];
  const cdwPrice = {
    0: 0,
    1: price?.cdw?.priceHalf?.customer ?? 0,
  };

  const getIncludedKm = () => {
    if (price.campaign?.includedKm && price.campaign?.status) {
      return price.campaign.includedKm;
    } else {
      return (
        price.base.unitIncludedKm * price.base.quantity +
        price.day.unitIncludedKm * price.day.quantity +
        price.hour.unitIncludedKm * price.hour.quantity +
        price.km.quantity
      );
    }
  };

  return (
    <FlexBoxColumn
      alignItems="start"
      px={"24px"}
      py={"40px"}
      width={"300px"}
      color={"#3E3E3E"}
    >
      <TextBold fontSize={"20px"} mb={"8px"}>
        Prisbas: {priceBase}
        {price.base.quantity > 1 && ` x${price.base.quantity}`}
      </TextBold>
      {price.base.unlimitedKm ? (
        <Text>Fria mil</Text>
      ) : (
        <Text>ink. {getIncludedKm() > 0 ? getIncludedKm() : 0} km</Text>
      )}

      <TextBold mt={"20px"} mb={"8px"} fontSize={"16px"}>
        {isHourPrice(price) ? "Timmar" : "Dagar"}
      </TextBold>
      <Text fontSize={"14px"}>
        {isHourPrice(price) ? price.hours : price.days}
      </Text>

      <TextBold mt={"20px"} mb={"8px"} fontSize={"16px"}>
        Totalt inkluderade km
      </TextBold>
      {price.base.unlimitedKm ? (
        <Text>Fria mil</Text>
      ) : (
        <Text fontSize={"14px"}>
          {getIncludedKm() > 0 ? getIncludedKm() : 0} km
        </Text>
      )}
      {additions &&
        (additions.otherAdditions?.length !== 0 ||
          additions.towbar.used ||
          additions.extraDrivers.amount ||
          additions.abroadPermit.name !== "") && (
          <Box width="100%">
            <TextBold fontSize="16px" mt={"20px"} mb={"8px"}>
              Tillägg
            </TextBold>
            {additions.abroadPermit.name !== "" && (
              <FlexBoxRow justifyContent={"space-between"}>
                <Text fontSize="14px">{additions.abroadPermit.name.NAMN}</Text>
                <Text fontSize="14px">
                  {getAdditionCost(additions.abroadPermit.name, price.days)} kr
                </Text>
              </FlexBoxRow>
            )}
            {additions.extraDrivers.amount > 0 && (
              <FlexBoxRow justifyContent={"space-between"}>
                <Text fontSize="14px">
                  {additions.extraDrivers.name} -{" "}
                  {additions.extraDrivers.amount} st
                </Text>
                <Text fontSize="14px">0 kr</Text>
              </FlexBoxRow>
            )}
            {additions.towbar.used && (
              <FlexBoxRow justifyContent={"space-between"}>
                <Text fontSize="14px">{additions.towbar.addition.NAMN}</Text>
                <Text fontSize="14px">
                  {formatPrice(
                    getAdditionCost(additions.towbar.addition, price.days)
                  )}
                </Text>
              </FlexBoxRow>
            )}
            {additions.otherAdditions?.map((addition) => (
              <FlexBoxRow justifyContent={"space-between"}>
                <Text fontSize="14px">
                  {addition.NAMN} - {addition.number} st
                </Text>
                <Text fontSize="14px">
                  {getAdditionCost(addition, price.days) * addition.number} kr
                </Text>
              </FlexBoxRow>
            ))}
          </Box>
        )}

      <Box width="100%">
        <TextBold fontSize="16px" mt={"20px"} mb={"8px"}>
          Skadeförsäkring
        </TextBold>
        <FlexBoxRow justifyContent={"space-between"}>
          <Text fontSize="14px">
            {cdwName[additions?.damageReduction ?? 0]}
          </Text>
          <Text fontSize="14px">
            {cdwPrice[additions?.damageReduction ?? 0]} kr
          </Text>
        </FlexBoxRow>
      </Box>

      <Box width="100%">
        <FlexBoxRow justifyContent={"space-between"} mt={"20px"} mb={"8px"}>
          <TextBold fontSize={"16px"}>Hyreskostnad</TextBold>
          <TextBold>
            {formatPrice(additions?.cost ?? price.total.customer)}
          </TextBold>
        </FlexBoxRow>
        <Text style={{ textAlign: "end", fontSize: "14px" }}>
          (ex. moms{" "}
          {formatPrice((additions?.cost ?? price.total.customer) / tax)})
        </Text>
      </Box>

      <Text fontSize={"12px"} fontStyle={"italic"} mt={"32px"}>
        Total kostnad baserad på den planerade körsträckan
      </Text>
    </FlexBoxColumn>
  );
};
export default PricePopUp;
