import { Box, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Context } from "../../context/Store";
import { auth, functions } from "../../database/firebase";
import StyledButton from "../styledButton/StyledButton";
import StyledInput from "../styledInput/StyledInput";
import { Text, TextBold } from "../text/Text";

const InputField = ({ label, value, onChange, type }) => {
  return (
    <Grid item xs={12}>
      <Text mb="10px">{label}</Text>
      <StyledInput
        value={value}
        onChange={onChange}
        type={type}
        bgcolor="#EEE"
      />
    </Grid>
  );
};

/**
 * Register account form
 * @author Erik Jonasson
 */
const LoginForm = ({
  submitForm,
  registerInstead,
  passwordReset,
  bookingView,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPasswordView, setForgotPasswordView] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setUser } = useContext(Context);

  const resetPassword = async ({ email }) => {
    if (loading) return;
    setLoading(true);
    await auth
      .sendPasswordResetEmail(email)
      .then(() => {
        passwordReset();
        setForgotPasswordView(false);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
          case "auth/user-not-found":
            setErrorMessage(
              "Ingen användare med den aktuella e-postadressen kunde hittas."
            );
            break;
          default:
            setErrorMessage(
              "Något gick fel, testa igen och kontakta folkes biluthyrning om problemet kvarstår"
            );
            break;
        }
      });

    setLoading(false);
  };

  const submit = async (event) => {
    event.preventDefault();

    setErrorMessage("");
    if (forgotPasswordView) {
      resetPassword({ email });
      return;
    }
    if (loading) return;
    setLoading(true);
    const getCustomer = functions.httpsCallable("customer-getCustomer");
    try {
      await auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => setUser(user));
      const customerInfo = await getCustomer().then(
        (customer) => customer.data
      );
      await submitForm(customerInfo);
    } catch (error) {
      if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(
          "Något gick fel, testa igen och kontakta folkes biluthyrning om problemet kvarstår"
        );
      }
    }
    setLoading(false);
  };

  return (
    <Box
      bgcolor={"#FFF"}
      border={"1.25px solid #F2F2F2"}
      borderRadius={5}
      padding={["20px", "40px"]}
      textAlign="left"
      maxWidth="240px"
      onSubmit={submit}
      component="form"
      width="100%"
    >
      <Text fontSize="24px" mb="20px">
        {forgotPasswordView ? "Glömt lösenord" : "Logga in"}
      </Text>
      <Box mb="30px" borderBottom="1.5px solid #E0E0E0"></Box>

      <Grid container spacing={2}>
        <InputField label="E-post" value={email} onChange={setEmail} required />
        {!forgotPasswordView && (
          <InputField
            label="Lösenord"
            value={password}
            type="password"
            onChange={setPassword}
          />
        )}
      </Grid>
      <Box my="30px">
        <StyledButton
          loading={loading}
          type="submit"
          onClick={submit}
          fullWidth
        >
          {forgotPasswordView && "Glömt lösenord?"}
          {!forgotPasswordView && !bookingView && "Logga in"}
          {!forgotPasswordView && bookingView && "Slutför"}
        </StyledButton>
      </Box>
      {errorMessage && (
        <TextBold fontSize="16px" color="red">
          {errorMessage}
        </TextBold>
      )}
      <Text
        onClick={registerInstead}
        style={{ textDecoration: "underline", cursor: "pointer" }}
        mt="20px"
      >
        Inte kund än? Registrera
      </Text>
      {passwordReset && (
        <Text
          onClick={() => {
            setPassword("");
            setForgotPasswordView(!forgotPasswordView);
          }}
          style={{ textDecoration: "underline", cursor: "pointer" }}
          mt="20px"
        >
          {forgotPasswordView ? "Logga in" : "Återställ lösenord"}
        </Text>
      )}
    </Box>
  );
};

export default LoginForm;
