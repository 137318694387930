import React, { createContext, useEffect, useMemo, useState } from "react";
import { functions } from "../database/firebase";

const Store = ({ children }) => {
  // Booking states
  const [bookingTime, setBookingTime] = useState(null);
  /**
   * name: {string}
   * modell: {string}
   * license: {string}
   * priceGroup: {string}
   * seats: {string}
   * fuel: {string}
   * info: {object}
   * carClass: {"Personbil"|"Buss"|"Lastbil"|"Släp"}
   * availableCars: [
   *    id: {string}
   *    transmission: {string}
   *    towbar: {string}
   *    milageScore: {number}
   * ]
   */
  const [car, setCar] = useState(null);
  /**
   * until: {Date}
   * carId: {string}
   */
  const [reservation, setReservation] = useState(null);
  const [bookingAdditions, setBookingAdditions] = useState(null);

  const [customerInfo, setCustomerInfo] = useState(null);
  const [user, setUser] = useState(null);
  const [changeBooking, setChangeBooking] = useState(null);

  const [priceLists, setPriceLists] = useState(null);
  const [openHours, setOpenHours] = useState(null);
  const [isOpenHoursLoading, setIsOpenHoursLoading] = useState(true);

  // Used for pluggin JSX elements to show in popup
  const [popUp, setPopUp] = useState(null);

  // Get pricelists and openhours
  useEffect(() => {
    const getPriceLists = functions.httpsCallable("priceLists-get");
    const getOpenHours = functions.httpsCallable("openHours-get");

    getPriceLists()
      .then((priceLists) => {
        const priceListsObject = JSON.parse(priceLists.data);
        setPriceLists(
          priceListsObject.map((priceList) => ({
            lists: priceList.lists,
            GILTIG_TILL: new Date(priceList.GILTIG_TILL),
            GILTIG_FRAN: new Date(priceList.GILTIG_FRAN),
          }))
        );
      })
      .catch((error) => console.log(error));

    getOpenHours()
      .then((openHours) => {
        setOpenHours(openHours.data);
        setIsOpenHoursLoading(false);
      })
      .catch((error) => {
        console.log("Could not load open hours");
        setIsOpenHoursLoading(false);
      });
  }, []);

  // Format regular openhours
  const standardTimes = useMemo(() => {
    const pad = (number) => {
      return ("0" + number).slice(-2);
    };
    if (!openHours?.weekDays) {
      return null;
    }
    let weekDays = openHours.weekDays;
    weekDays.sort((a, b) => a.DAGNR - b.DAGNR);

    // Weather to use "vardag"
    let workday = true;
    const monday = weekDays[0];
    for (const day of weekDays) {
      if (day.dagNR < 6) {
        // If not weekend
        if (day.FRAN !== monday.FRAN && day.TILL !== monday.TILL)
          workday = false;
      }
    }

    if (workday) {
      // Remove all the workdays and replace with vardag infront
      weekDays = weekDays.filter((day) => day.DAGNR >= 6);
      weekDays.unshift({ ...monday, DAG: "Vardagar" });
    }
    weekDays = weekDays.map((day, i) => {
      return {
        dayNr: day.DAGNR,
        day: day.DAG,
        time: `${pad(day.FRAN)}.00 - ${pad(day.TILL)}.00`,
      };
    });
    weekDays.sort((a, b) => a.DAGNR - b.DAGNR);
    return weekDays;
  }, [openHours]);

  return (
    <Context.Provider
      value={{
        car,
        setCar,
        priceLists,
        bookingTime,
        setBookingTime,
        bookingAdditions,
        setBookingAdditions,
        reservation,
        setReservation,
        customerInfo,
        setCustomerInfo,
        popUp,
        setPopUp,
        openHours,
        standardTimes,
        isOpenHoursLoading,
        user,
        setUser,
        changeBooking,
        setChangeBooking,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const Context = createContext();
export default Store;
