import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import {
  forwardRef,
  React,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Text, TextBold } from "../text/Text";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import { dateToString, daysBetween } from "../../utils/date";
import scheduleIcon from "../../assets/icons/schedule.svg";
import damageReductionIcon from "../../assets/icons/damage_reduction.svg";
import { formatPrice } from "../../utils/formating";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import Picture from "../picture/Picture";
import carTypeIcon from "../../assets/icons/cardIcons/car_type.svg";
import priceGroupIcon from "../../assets/icons/cardIcons/price_group.svg";
import seatsIcon from "../../assets/icons/cardIcons/seats.svg";
import transmissionIcon from "../../assets/icons/cardIcons/transmission.svg";
import fuelIcon from "../../assets/icons/cardIcons/fuel.svg";
import plusIcon from "../../assets/icons/plus.svg";
import drivingDistanceIcon from "../../assets/icons/cardIcons/driving_distance.svg";
import { Context } from "../../context/Store";
import CustomAccordion from "../customAccordion/CustomAccordion";
import { tax } from "../../utils/price";

const InfoText = ({ icon, text }) => {
  return (
    <FlexBoxRow mb={1} justifyContent={["left", "left", "left"]}>
      <img
        src={icon}
        alt=""
        style={{ width: "20px", height: "19px", marginRight: "10px" }}
      />
      <Text mr={[0, 0, 1]}>{text}</Text>
    </FlexBoxRow>
  );
};

const CarCardBooking = (props) => {
  const { car, bookingTime, bookingAdditions, viewExpanderTriggerFunction } =
    props;
  // const { includedDistance } = useContext(Context);
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const { modell, carClass } = car;
  const {
    name,
    modell,
    carClass,
    priceGroup,
    seats,
    fuel,
    license,
    info,
    moreInfo,
  } = car;
  const distance = bookingTime.distance;
  const pickUpDate = dateToString(bookingTime.startDate);
  const returnDate = dateToString(bookingTime.endDate);
  const days = daysBetween(bookingTime.startDate, bookingTime.endDate);

  const cost = formatPrice(bookingAdditions.cost);
  const noTaxCost = formatPrice(bookingAdditions.noTaxCost);
  const includedDistance = bookingAdditions.includedDistance;

  const getAddons = (additions) => {
    return bookingAdditions.additionList;
  };

  const [addons, setAddons] = useState(getAddons(bookingAdditions));

  const transmission = useMemo(() => {
    let transmissions = [];
    for (const carItem of car.availableCars) {
      if (carItem.transmission === "Automat" && !transmissions.includes("A")) {
        transmissions.push("A");
      }
      if (carItem.transmission === "Manuell" && !transmissions.includes("M")) {
        transmissions.push("M");
      }
    }
    transmissions.sort();
    return transmissions.join(" eller ");
  }, car);

  const detailComponents = (
    <>
      <Box textAlign="left" fontSize="16px" width={"100%"}>
        <TextBold mb="10px" fontSize="18px">
          {modell}
        </TextBold>
        <Text mb="10px">{pickUpDate} -</Text>
        <Text mb="10px">{returnDate}</Text>
        <Grid container columns={2} spacing={1}>
          {license && (
            <Grid item mx={["10px", "10px", "0px"]} xs={6}>
              <InfoText icon={priceGroupIcon} text={license} />
            </Grid>
          )}
          {priceGroup && (
            <Grid item mx={["10px", "10px", "0px"]} xs={6}>
              <InfoText icon={carTypeIcon} text={priceGroup} />
            </Grid>
          )}

          {seats && (
            <Grid item mx={["10px", "10px", "0px"]} xs={6}>
              <InfoText icon={seatsIcon} text={seats} />
            </Grid>
          )}
          {transmission && (
            <Grid item mx={["10px", "10px", "0px"]} xs={6}>
              <InfoText icon={transmissionIcon} text={transmission} />
            </Grid>
          )}
          {fuel && (
            <Grid item mx={["10px", "10px", "0px"]} xs={6}>
              <InfoText icon={fuelIcon} text={fuel} />
            </Grid>
          )}
          {addons.towbarAddition && (
            <Grid item mx={["10px", "10px", "0px"]} xs={6}>
              <InfoText icon={carTypeIcon} text={"Dragkrok"}/>
            </Grid>
          )}
        </Grid>
        <Box my="15px" borderBottom="1.5px solid #E0E0E0"></Box>
        <FlexBoxRow my="15px">
          <img src={scheduleIcon} alt="" style={{ width: "20px" }} />
          <Text ml="10px">
            {days} {days > 1 ? "dagar" : "dag"}
          </Text>
        </FlexBoxRow>
        <Box my="15px" borderBottom="1.5px solid #E0E0E0"></Box>
        <FlexBoxRow my="15px">
          <img src={drivingDistanceIcon} alt="" style={{ width: "20px" }} />
          <TextBold ml="10px">Körsträcka</TextBold>
        </FlexBoxRow>
        <FlexBoxRow justifyContent="space-between" my="15px">
          <Text ml={"30px"}>Planerad</Text>
          <Text textAlign="right">{distance} km</Text>
        </FlexBoxRow>
        {includedDistance ? (
          <FlexBoxRow justifyContent="space-between" my="15px">
            <Text ml={"30px"}>Ingår</Text>
            <Text>{includedDistance} km</Text>
          </FlexBoxRow>
        ) : (
          <></>
        )}
        <Box my="15px" borderBottom="1.5px solid #E0E0E0"></Box>
        {addons.length > 0 &&
          addons.map((addonObj, index) => {
            return index === 0 ? ( // First element has icon next to it
              <>
                <FlexBoxRow justifyContent="space-between" my="15px">
                  <FlexBoxRow>
                    <img src={plusIcon} alt="" style={{ width: "20px" }} />
                    <Text ml={"10px"}>{addonObj.beskrivning + " - " + addonObj.number}</Text>
                  </FlexBoxRow>
                  <Text textAlign="right">{addonObj.KOSTNAD * tax} kr</Text>
                </FlexBoxRow>
              </>
            ) : (
              <>
                <FlexBoxRow justifyContent="space-between" my="15px">
                  <Text ml={"30px"}>{addonObj.beskrivning}</Text>
                  <Text textAlign="right">{addonObj.KOSTNAD * tax} kr</Text>
                </FlexBoxRow>
              </>
            );
          })}
        {addons.length > 0 && (
          <Box my="15px" borderBottom="1.5px solid #E0E0E0"></Box>
        )}
        <Box>
          <FlexBoxRow>
            <img src={damageReductionIcon} alt="" style={{width: "25px"}}/>
            <TextBold ml="5px">
              Skadeförsäkring
            </TextBold>
          </FlexBoxRow>
          {carClass !== "Släp" ? (

            <FlexBoxRow justifyContent="space-between" my="15px">
              <Text ml={"30px"}>
                {bookingAdditions.damageReduction == 0
                  ? "Basförsäkring"
                  : "Självriskreducering"}
              </Text>
              <Text>{formatPrice(bookingAdditions.damageReductionCost)}</Text>
            </FlexBoxRow>


          ) : (
            <Text ml="5px">Skadekostnadsreducering: Inkluderat</Text>
          )}
        </Box>
      </Box>
    </>
  );

  return (
    <FlexBoxColumn
      bgcolor={"#FFF"}
      border={"1.25px solid #F2F2F2"}
      borderRadius={5}
      alignItems="stretch"
      width={sm || "300px"}
      maxWidth="560px"
      minWidth="300px"
      {...props}
    >
      <Box m="40px" mb="15px" textAlign="left" fontSize="16px">
        <Text fontSize="24px" mb="20px">
          Din bokning
        </Text>
        <Box my="15px" borderBottom="1.5px solid #E0E0E0"></Box>

        <Picture
          name={name}
          carClass={carClass}
          width="100%"
          border="5px 5px 0px 0px"
        />
        <Hidden smDown>{detailComponents}</Hidden>
        <Hidden mdUp>
          <CustomAccordion
            title="Detaljer"
            backgroundColor="white"
            onChange={(change) => viewExpanderTriggerFunction(change)}
          >
            {detailComponents}
          </CustomAccordion>
        </Hidden>
        <Hidden smDown>
          <Box my="15px" borderBottom="1.5px solid #E0E0E0"></Box>
          <FlexBoxRow justifyContent="space-between">
            <TextBold>Hyreskostnad</TextBold>
            <TextBold>{cost}</TextBold>
          </FlexBoxRow>
          <FlexBoxRow justifyContent="end">
            <Text style={{ color: "#3E3E3E" }} fontSize="14px">
              (ex. moms {noTaxCost})
            </Text>
          </FlexBoxRow>
          <Text
            style={{ color: "#3E3E3E" }}
            fontSize="12px"
            fontStyle="italic"
            mt="15px"
          >
            Total kostnad baserad på den planerade körsträckan.
          </Text>
        </Hidden>
      </Box>
    </FlexBoxColumn>
  );
};

export default CarCardBooking;
