export const carClassParam = {
  Personbil: "p",
  Buss: "b",
  Lastbil: "l",
  Släp: "s",
};

export const search = (
  history,
  { carClass, transmission, startDate, endDate, distance, towbar }
) => {
  const transmissionParam = {
    Automat: "a",
    Manuell: "m",
    "A & M": "b",
    "None": "n"
  };

  history.push(
    `/sok/b=${carClassParam[carClass]}&v=${
      transmissionParam[transmission]
    }&sd=${Number(startDate)}&ed=${Number(endDate)}&km=${distance}&d=${
      towbar ? "1" : "0"
    }`
  );
};

export const getSearchParams = (queries) => {
  const carClasses = {
    p: "Personbil",
    b: "Buss",
    l: "Lastbil",
    s: "Släp",
  };
  const transmissionTypes = {
    b: "A & M",
    a: "Automat",
    m: "Manuell",
    n: "None"
  };
  const params = new URLSearchParams(queries);
  const carClass = carClasses[params.get("b")] ?? "Personbil";
  const transmission = transmissionTypes[params.get("v")] ?? "Automat";
  const towbar = params.get("d") === "1";
  let startDate = new Date(Number(params.get("sd")));
  if (!(startDate instanceof Date) || isNaN(startDate)) {
    startDate = new Date();
  }
  let endDate = new Date(Number(params.get("ed")));
  if (!(endDate instanceof Date) || isNaN(endDate)) {
    endDate = new Date();
  }
  let distance = Number(params.get("km")) ?? 0;
  distance = Math.min(distance, 99999);
  distance = Math.max(distance, 0);
  distance = Math.floor(distance);

  return { carClass, transmission, startDate, endDate, distance, towbar };
};
