import React, {useMemo} from "react";
import {NavLink, useLocation} from "react-router-dom";
import "./burgerButton.scss";

/**
 * Burger button for the header
 * @author: Erik Jonasson
 */
const BurgerButton = ({open, onClick}) => {
  const {pathname} = useLocation();
  // Change style based on page
  const pageClass = useMemo(() => {
    return pathname === "/" ? "burgerContainerHomepage" : "";
  }, [pathname]);

  return (
    <div
      className={`burgerContainer ${pageClass}`}
      onClick={onClick}
      style={{position: open ? "fixed" : "absolute"}}
    >

      <NavLink exact to="/avboka"
               style={{
                 opacity: open ? "0" : "1",
                 fontSize: 18,
               }}>
        Avboka
      </NavLink>
      <div className={"burgerButton"}>
        <div
          style={{
            transform: open ? "rotate(45deg)" : "rotate(0)",
            background: open ? "#FFF" : "",
          }}
        />
        <div
          style={{
            opacity: open ? "0" : "1",
            transform: open ? "translateX(20px)" : "translateX(0)",
          }}
        />
        <div
          style={{
            transform: open ? "rotate(-45deg)" : "rotate(0)",
            background: open ? "#FFF" : "",
          }}
        />
      </div>
    </div>
  );
};

export default BurgerButton;
