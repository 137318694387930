import { Box } from "@material-ui/core";
import React from "react";

const FlexBoxRow = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems={props.alignItems ?? "center"}
      {...props}
    ></Box>
  );
};

export default FlexBoxRow;
