import { Box } from "@material-ui/core";
import React from "react";
import { Text } from "../text/Text";
import FlexBoxRow from "../flexBox/FlexBoxRow";

const NoticeCard = ({ carClass }) => {
  return (
    (carClass === "Släp" ||
      carClass === "Lastbil") && (
      <Box
        bgcolor={"#FFF"}
        border={"2px solid #F2F2F2"}
        borderRadius={5}
        display="flex"
        py={"15px"}
        mt={"10px"}
        px={["10px", "15px", "20px"]}
      >
        <FlexBoxRow justifyContent="flex-start">
          <Text textAlign="start" ml="30px">
            Minimumhyra: 4 timmar
          </Text>
        </FlexBoxRow>
      </Box>
    )
  );
};

export default NoticeCard;
