import { makeStyles, Radio } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30px",
    height: "30px",
    padding: "0px",
  },
}));

const RadioButton = ({ onChange, checked, value }) => {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      checked={checked}
      onChange={onChange}
      color="primary"
      value={value}
    />
  );
};

export default RadioButton;
