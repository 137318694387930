const Dev = {
  apiKey: "AIzaSyAWUDr8nkPwU0qyWOdBcu2JLmpHaCO27D8",
  authDomain: "folkes-biluthyrning-customer.firebaseapp.com",
  databaseURL: "https://folkes-biluthyrning.firebaseio.com",
  projectId: "folkes-biluthyrning",
  storageBucket: "folkes-biluthyrning.appspot.com",
  messagingSenderId: "321224323659",
  appId: "1:321224323659:web:20dd566df887d1c7dd93ce",
  measurementId: "G-KR004955DX",
};

export default Dev;
