import React from "react";
import "./footer.scss";
import { Hidden, Typography } from "@material-ui/core";
import { useContext } from "react";
import { Context } from "../../context/Store";
import { IntegrityPolicy } from "../integrityPolicy/IntegrityPolicy";

/**
 * Footer component
 * @author: Erik Jonasson
 */
const Footer = () => {
  const { setPopUp } = useContext(Context);
  const openPolicy = () => {
    setPopUp(IntegrityPolicy);
  };
  return (
    <footer className="footer">
      <div className="footerContainer">
        <div className="footerColumn">
          <div className="footerInfo">
            <Typography>Folkes Biluthyrning AB</Typography>
            <Hidden smDown>
              <div className="footerBar">
                <Typography>|</Typography>
              </div>
            </Hidden>
            <Typography>Sellerigatan 5</Typography>
            <Hidden smDown>
              <div className="footerBar">
                <Typography>|</Typography>
              </div>
            </Hidden>
            <Typography>224 78, Lund</Typography>
          </div>
          <div className="footerInfo">
            <Typography>
              <a href="mailto:info@folkesbiluthyrning.se">
                info@folkesbiluthyrning.se
              </a>
            </Typography>
            <Hidden smDown>
              <div className="footerBar">
                <Typography>|</Typography>
              </div>
            </Hidden>
            <Typography>
              <a href="tel:+46-46-354140">046-354140</a>
            </Typography>
          </div>
        </div>
        <div className="footerEnd">
          <div className="footerWeknowit">
            <Typography>Developed by </Typography>
            <a
              href="https://weknowit.se/"
              target="_blank"
              rel="noopener noreferrer"
            >
              We Know IT
            </a>
          </div>
          <Typography className="footerPolicy pointer" onClick={openPolicy}>
            Integritetspolicy
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;