import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import arrowIcon from "../../assets/icons/dropdown_arrow_red.svg";
import {Text} from "../text/Text";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import React from "react";


const CustomAccordion = ({title, children, backgroundColor, onChange}) => {
  return(
    <Accordion
      disableGutters
      elevation={0}
      margin={"auto"}
      onChange={onChange}
      style={{backgroundColor: backgroundColor ?? "#F9F9F9", boxShadow: "none", border: 0, margin: "auto",
        minHeight: "20px", padding: 0, position: "initial"}}>
      <AccordionSummary
        style={{width: "70px", paddingRight: "100px", padding: 0, margin: 0, border: 0, height: "20px", minHeight:"20px"}}
        expandIcon={<img src={arrowIcon} alt="arrow"
                         style={{transform: "rotate(180deg)", width: "8px"}}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Text color={"#EC2B34"} fontSize={14}>{title}</Text>
      </AccordionSummary>
      <AccordionDetails
        style={{padding: "10px 0 0 0", width: "100%", border: 0}}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
export default CustomAccordion