import { functions } from "../../../database/firebase";
import { tax } from "../../../utils/price";

const getAdditions = functions.httpsCallable("additions-get");
export const fetchAdditions = async (startDate, endDate) => {
  try {
    let { additions, nrOfChildSeats, nrOfChildPillows } = await getAdditions({
      startDate: Number(startDate),
      endDate: Number(endDate),
    }).then((res) => {
      return res.data;
    });
    // Sets max amount of child seats and pillows
    nrOfChildSeats = Math.min(nrOfChildSeats, 2);
    nrOfChildPillows = Math.min(nrOfChildPillows, 2);
    additions.find((addition) => addition.NAMN === "Bilbarnstol").maxAmount =
      nrOfChildSeats;
    additions.find((addition) => addition.NAMN === "Bälteskudde").maxAmount =
      nrOfChildPillows;

    const towbarAddition = additions.find(
      (addition) => addition.NAMN === "Dragkrok"
    );
    additions = additions.filter((addition) => addition.NAMN !== "Dragkrok");
    let abroadAdditions = additions.filter(
      (addition) => addition.UTLANDSTILLSTAND
    );
    additions = additions.filter((addition) => !addition.UTLANDSTILLSTAND);
    abroadAdditions = abroadAdditions.map((addition) => ({
      value: addition,
      label: addition.NAMN,
    }));
    additions = additions.map((addition) => ({
      ...addition,
      used: false,
      number: 1,
      maxAmount: addition?.maxAmount ?? 2,
    }));
    additions = additions.filter((addition) => addition.maxAmount > 0);
    return {
      abroadAdditions,
      additions,
      towbarAddition,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAdditionCost = (addition, days) => {
  if (addition.FAST_KOSTNAD) {
    return addition.KOSTNAD * tax;
  } else if (addition.MAX_KOSTNAD !== 0) {
    return Math.min(addition.KOSTNAD * days, addition.MAX_KOSTNAD) * tax;
  } else {
    return addition.KOSTNAD * days * tax;
  }
};
