const Prod = {
  apiKey: "AIzaSyCodFYLW7p2xBuKcjgr2wgcng5l5pzmSc4",
  authDomain: "folkes-biluthyrning-prod.firebaseapp.com",
  projectId: "folkes-biluthyrning-prod",
  storageBucket: "folkes-biluthyrning-prod.appspot.com",
  messagingSenderId: "318035646358",
  appId: "1:318035646358:web:4120d44a9772914006efb7",
  measurementId: "G-VJFL8MX478",
};

export default Prod;