import React, { useContext, useEffect, useState } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import svLocale from "date-fns/locale/sv";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.css";
import { Portal } from "react-overlays";
import { Box } from "@material-ui/core";
import { TextBold, Text } from "../text/Text";
import { Context } from "../../context/Store";
import { useIsMobile } from "../../utils/MediaQuery";
import FlexBoxRow from "../flexBox/FlexBoxRow";

/**
 * @param selected {Date object} Selected date.
 * @param handleSelect {Function} Parent function to handle date updates.
 * @param style {Object} Styling for component wrapper, used primarily to set width, margin-right and margin-bottom.
 *
 * @param minDate {Date object} First selectable date.
 * @param maxDate {Date object} Last selectable date.
 *
 * @param showTimeSelect {Boolean} If true time selector will be displayed.
 * @param minHour {Number} First selectable hour (inclusive). Defaults to 0.
 * @param minMinute {Number} First selectable minute (inclusive). Defaults to 0.
 * @param maxHour {Number} Last selectable hour (inclusive). Defaults to 23.
 * @param maxMinute {Number} Last selectable minute (inclusive). Defaults to 59.
 *
 * @param maxTime {String} Last selectable time (inclusive). Only used if showTimeSelect is true. Should have format "hh:mm".
 *
 * @author: Jakob Sjöqvist
 */

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

const CalendarWrapper = ({ className, children }) => {
  const { standardTimes } = useContext(Context);
  return (
    <Box className={className} display="flex" flexDirection={["column", "row"]}>
      <Box style={{ position: "relative" }}>{children}</Box>
      <Box
        fontSize="20px"
        p="10px"
        borderTop={["1.25px solid #F2F2F2", ""]}
        borderLeft={["", "1.25px solid #F2F2F2"]}
      >
        <TextBold
          pb={["5px", "10px"]}
          mb={["5px", "10px"]}
          borderBottom="3px solid #F2F2F2"
        >
          Ordinarie öppettider
        </TextBold>
        {standardTimes ? (
          standardTimes.map((day) => {
            return (
              <FlexBoxRow
                width={["auto", "auto"]}
                px="10px"
                alignItems="flex-start"
                justifyContent={["space-between", "space-between"]}
              >
                <Text textAlign={"left"} width={"40%"}>
                  {day.day}
                </Text>
                <Text
                  display="inline"
                  style={{ fontVariantNumeric: "tabular-nums" }}
                >
                  {day.time}
                </Text>
              </FlexBoxRow>
            );
          })
        ) : (
          <Text>Kunde inte hämta öppettider</Text>
        )}
        <Text pt="15px" px="10px" fontSize="18px" width="100%" textAlign="left">
          {"Återlämning möjligt dygnet runt"}
        </Text>
        <Text pt="15px" px="10px" fontSize="18px" width="250px" textAlign="left">
          {" Är kontoret stängt återlämnas nyckel via nyckelinkastet"}
        </Text>
      </Box>
    </Box>
  );
};

registerLocale("sv-SE", svLocale);

const Datepicker = (props) => {
  const { openHours } = useContext(Context);
  const [excludeDates, setExcludedDates] = useState([]);

  const filterTime = (time) => {
    if (props.showTimeSelect && props.minDate) {
      let lowerBound = new Date(props.minDate);
      lowerBound.setMinutes(lowerBound.getMinutes() + 29);

      return lowerBound <= time;
    } else {
      return true;
    }
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    if (props.selectsEnd){
      setExcludedDates([]); 
    }else{
      setExcludedDates(
        openHours?.redDays
          .filter((day) => day.STANGT)
          .map((day) => new Date(day.DATUM))
      );
    }
  }, [openHours]);

  return (
    <div style={props.style}>
      <DatePicker
        dateFormat={props.showTimeSelect ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
        locale="sv-SE"
        selected={props.selected}
        onChange={(date) => props.handleSelect({ value: date })}
        className="datepickerInput"
        showTimeSelect={false || props.showTimeSelect}
        timeCaption="Tid"
        minDate={props.minDate ? props.minDate : ""}
        maxDate={props.maxDate ? props.maxDate : ""}
        timeIntervals={15}
        filterTime={props.filterTime ?? filterTime}
        filterDate={props.filterDate}
        selectsStart={props.selectsStart}
        selectsEnd={props.selectsEnd}
        startDate={props.startDate}
        endDate={props.endDate}
        excludeDates={excludeDates}
        size={9}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        onFocus={(e) => (e.target.readOnly = isMobile)}
        onBlur={(e) => (e.target.readOnly = isMobile)}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
        popperContainer={CalendarContainer}
        calendarContainer={CalendarWrapper}
      />
    </div>
  );
};
export default Datepicker;
