import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Text, TextBold } from "../../text/Text";
import dropdownArrow from "../../../assets/icons/dropdown_arrow_red.svg";
import { functions } from "../../../database/firebase";
import { ReactComponent as LoadingIcon } from "../../../assets/icons/loading.svg";

const FAQ = () => {
  useEffect(() => {
    document.title = "FAQ";
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [faqList, setFaqList] = useState();

  useEffect(() => {
    const getFAQ = functions.httpsCallable("faq-getFAQ");
    (async () => {
      try {
        const faq = await getFAQ().then((res) => {
          return res.data;
        });
        setFaqList(faq);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Container maxWidth="md">
      <TextBold
        component="h1"
        fontSize={["24px", "24px", "35px"]}
        mt="30px"
        mb="40px"
      >
        F.A.Q
      </TextBold>
      {!isLoading ? (
        <>
          {" "}
          {faqList.map((item, i) => {
            return (
              <Box key={i} my="10px" maxWidth="550px" mx="auto">
                <TextBold
                  mt="20px"
                  textAlign="left"
                  fontSize={["18px", "20px", "26px"]}
                >
                  {item.category}
                </TextBold>

                {item.faq.map((value, k) => {
                  return (
                    <Box key={k} my="10px" maxWidth="550px" mx="auto">
                      <Accordion style={{ marginTop: 8 }}>
                        <AccordionSummary
                          expandIcon={<img src={dropdownArrow} alt="" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <TextBold
                            textAlign="left"
                            fontSize={["18px", "18px", "20px"]}
                          >
                            {value.question}
                          </TextBold>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Text
                            fontSize="16px"
                            textAlign="left"
                            className="enterWrap"
                          >
                            {value.answer}
                          </Text>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </Box>
            );
          })}{" "}
        </>
      ) : (
        <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
      )}
      <Box mb={["50px", "80px"]}></Box>
    </Container>
  );
};
export default FAQ;
