import { Box, Grid, Hidden, Radio } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../context/Store";
import { functions } from "../../database/firebase";
import PersonNumberInput from "../personNumberInput/PersonNumberInput";
import StyledButton from "../styledButton/StyledButton";
import StyledInput from "../styledInput/StyledInput";
import { Text, TextBold } from "../text/Text";

const InputField = ({ label, value, onChange, inputMode, disabled }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Text mb="10px">{label}</Text>
      <StyledInput
        borderRadius="5px"
        value={value}
        inputMode={inputMode}
        onChange={onChange}
        bgcolor="#EEE"
        disabled={disabled}
      />
    </Grid>
  );
};

/**
 * Register account form
 * @author Erik Jonasson
 * @todo Change dummy values
 */
const RegisterForm = ({
  submitForm,
  loginInstead,
  bookingView,
  changeInfo,
  user,
  register,
}) => {
  const [isPerson, setIsPerson] = useState(true);
  const { customerInfo } = useContext(Context);
  const [firstName, setFirstName] = useState(customerInfo?.firstName || "");
  const [lastName, setLastName] = useState(customerInfo?.lastName || "");
  const [email, setEmail] = useState(customerInfo?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(
    customerInfo?.phoneNumber || ""
  );
  const [personNumber, setPersonNumber] = useState(
    customerInfo?.personNumber || ""
  );
  const [address, setAddress] = useState(customerInfo?.address || "");
  const [postNumber, setPostNumber] = useState(customerInfo?.postNumber || "");
  const [city, setCity] = useState(customerInfo?.city || "");

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  useEffect(() => {
    setFirstName(customerInfo?.firstName || "");
    setLastName(customerInfo?.lastName || "");
    setEmail(customerInfo?.email || "");
    setPhoneNumber(customerInfo?.phoneNumber || "");
    setPersonNumber(customerInfo?.personNumber || "");
    setAddress(customerInfo?.address || "");
    setPostNumber(customerInfo?.postNumber || "");
    setCity(customerInfo?.city || "");
  }, [customerInfo]);

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (loading) return;

    setErrorMessage("");
    if (firstName === "") {
      setErrorMessage("Förnamn saknas");
    } else if (lastName === "") {
      setErrorMessage("Efternamn saknas");
    } else if (email === "") {
      setErrorMessage("E-post saknas");
    } else if (!validateEmail(email)) {
      setErrorMessage("E-post format är fel");
    } else if (phoneNumber === "") {
      setErrorMessage("Telefonnummer saknas");
    } else if (personNumber === "" || personNumber.includes("_")) {
      setErrorMessage("Ogiligt personnummer/organisationsnummer");
    } else {
      const inputFields = {
        firstName,
        lastName,
        email,
        phoneNumber,
        personNumber,
        address,
        postNumber,
        city,
        isPerson,
      };

      await submitForm(inputFields);
    }
    setLoading(false);
  };

  return (
    <Box
      bgcolor={"#FFF"}
      border={"1.25px solid #F2F2F2"}
      borderRadius={5}
      padding="40px"
      textAlign="left"
      maxWidth="480px"
      onSubmit={submit}
      component="form"
    >
      <Text fontSize="24px" mb="20px">
        {bookingView ? "Dina uppgifter" : "Registrera"}
      </Text>
      <Box mb="30px" borderBottom="1.5px solid #E0E0E0"></Box>
      <Box ml="-9px" mb="20px" display="flex" alignItems="center">
        <Radio
          color="primary"
          value="privatperson"
          checked={isPerson}
          onChange={() => setIsPerson(true)}
          disabled={user}
          name="privatperson"
        />
        <Text>Privatperson</Text>
        <Radio
          disabled={user}
          color="primary"
          value="företag"
          checked={!isPerson}
          onChange={() => setIsPerson(false)}
          name="företag"
        />
        <Text>Företag</Text>
      </Box>
      <Grid container spacing={2}>
        <InputField
          label={isPerson ? "Förnamn *" : "Företagets namn *"}
          value={firstName}
          disabled={user}
          onChange={setFirstName}
        />
        <InputField
          label={isPerson ? "Efternamn *" : "För- och efternamn *"}
          value={lastName}
          disabled={user}
          onChange={setLastName}
        />
        <InputField
          label="E-post *"
          value={email}
          onChange={setEmail}
          disabled={user}
        />
        <InputField
          label="Telefonnummer *"
          value={phoneNumber}
          inputMode="tel"
          disabled={user}
          onChange={setPhoneNumber}
        />
        <Grid item xs={12} sm={6}>
          <Text mb="10px">
            {isPerson ? "Personnummer *" : "Organisationsnummer *"}
          </Text>
          <PersonNumberInput
            value={personNumber}
            inputMode="numeric"
            disabled={user}
            onChange={setPersonNumber}
            bgcolor="#EEE"
          />
        </Grid>
        <InputField
          label="Adress"
          value={address}
          onChange={setAddress}
          disabled={user}
        />
        <InputField
          label="Postnummer"
          inputMode="numeric"
          disabled={user}
          value={postNumber}
          onChange={setPostNumber}
        />
        <InputField
          label="Stad"
          value={city}
          onChange={setCity}
          disabled={user}
        />
      </Grid>
      {user ? (
        <></>
      ) : (
        <Text mt="40px" mb="20px">
          * Obligatoriska fält
        </Text>
      )}

      <Box my="30px">
        <StyledButton
          loading={loading}
          type="submit"
          onClick={submit}
          fullWidth
        >
          {register ? (
            <Hidden xsDown>
              <TextBold fontSize="18px">Registrera</TextBold>
            </Hidden>
          ) : (
            <>
              <Hidden xsDown>
                <TextBold fontSize="18px">
                  Slutför bokningsförfrågan - Betala senare
                </TextBold>
              </Hidden>

              <Hidden smUp>
                <TextBold fontSize="18px">Slutför bokningsförfrågan</TextBold>
                <TextBold fontSize="18px">Betala senare</TextBold>
              </Hidden>
            </>
          )}
        </StyledButton>
        {/* {user ? (
          <Text
            onClick={() => {
              console.log("test");
              changeInfo();
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            mt="20px"
          >
            Ändra uppgifter
          </Text>
        ) : (
          <Text
            onClick={loginInstead}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            mt="20px"
          >
            Redan kund? Logga in
          </Text>
        )} */}
      </Box>
      {errorMessage && (
        <TextBold fontSize="16px" color="red">
          {errorMessage}
        </TextBold>
      )}
    </Box>
  );
};

export default RegisterForm;
