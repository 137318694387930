import firebaseProd from "./firebase.prod";
import firebaseDev from "./firebase.dev";
/*
 * Project wide configuration varaibles.
 */

const Config = {
  // Only use Firebase production project if environment is explicitly set to production else use development.
  firebaseDev: firebaseDev,
  firebase: firebaseProd,
  environment: process.env.NODE_ENV,
  env: process.env.NODE_ENV,
};

export default Config;
