import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { formatPrice } from "../../utils/formating";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import { TextBold, Text } from "../text/Text";

const StickyPrice = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { scrollBreakPoint, cost, noTaxCost } = props;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const formattedCost = formatPrice(cost);
  const formattedNoTaxCost = formatPrice(noTaxCost);

  return (
    <Box
      position="sticky"
      top="0px"
      left="0px"
      zIndex={2}
      justifyContent={"center"}
      width="100%"
      mb={"30px"}
      display="flex"
    >
      <Box
        width={"100%"}
        maxWidth={props.maxWidth ?? "560px"}
        minWidth={"300px"}
        margin="0px auto"
        // hidden={scrollPosition < scrollBreakPoint}
        bgcolor={"white"}
        border={"1.25px solid #F2F2F2"}
        borderRadius={5}
        pb={"20px"}
      >
        <FlexBoxRow
          pt={"20px"}
          mx={"40px"}
          justifyContent="space-between"
        >
          <TextBold>Hyreskostnad</TextBold>
          <TextBold>{formattedCost}</TextBold>
        </FlexBoxRow>
        {noTaxCost && scrollPosition < scrollBreakPoint && (
          <Box textAlign={"left"}>
            <FlexBoxRow mx={"40px"} justifyContent="end">
              <Text style={{ color: "#3E3E3E" }} fontSize="14px">
                (ex. moms {formattedNoTaxCost})
              </Text>
            </FlexBoxRow>
            <Text
              mx={"40px"}
              style={{ color: "#3E3E3E" }}
              fontSize="12px"
              fontStyle="italic"
              mt="15px"
            >
              Total kostnad baserad på den planerade körsträckan.
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StickyPrice;
