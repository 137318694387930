import React, { useContext } from "react";
import { Context } from "../../context/Store";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import FlexBoxRow from "../flexBox/FlexBoxRow";

import xIcon from "../../assets/icons/x.svg";
import { Text } from "../text/Text";

/**
 * Popup component
 * The popup is set by setPopUp in context,
 * with a list of {header: "header", info: "info" }
 * @author Erik Jonasson
 */
const PopUp = () => {
  const { popUp, setPopUp } = useContext(Context);

  const close = () => {
    setPopUp(null);
  };

  return (
    <>
      {popUp && (
        <FlexBoxRow
          position="fixed"
          width="100%"
          height="100%"
          bgcolor="#00000066"
          justifyContent="center"
          zIndex="150"
          onClick={close}
        >
          <FlexBoxColumn
            bgcolor={"#FFF"}
            border={"1.25px solid #F2F2F2"}
            borderRadius={5}
            maxWidth="900px"
            zIndex="200"
            m={["5px", "30px"]}
            p={["5px", "35px"]}
            pt={[0, 0]}
            onClick={(event) => event.stopPropagation()}
            maxHeight="95%"
            overflow="hidden"
          >
            {popUp}
            <FlexBoxRow onClick={close} mt="20px" style={{ cursor: "pointer" }}>
              <img src={xIcon} alt="" />
              <Text style={{ textDecoration: "underline" }} ml="10px">
                Stäng
              </Text>
            </FlexBoxRow>
          </FlexBoxColumn>
        </FlexBoxRow>
      )}
    </>
  );
};

export default PopUp;
