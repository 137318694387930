import { Box } from "@material-ui/core";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import { Text, TextBold } from "../text/Text";

const PolicyHeader = ({ children }) => {
  return (
    <Box mt="10px">
      <TextBold>{children}</TextBold>
    </Box>
  );
};
const ListItem = ({ children, item }) => {
  item.number = item.number + 1;
  return (
    <Text>
      {item.number}. {children}
    </Text>
  );
};
const SubListItem = ({ children }) => {
  let item = { number: 0 };
  return (
    <Box ml="20px" mb="10px">
      {children.map((listItem) => {
        return <ListItem item={item}>{listItem}</ListItem>;
      })}
    </Box>
  );
};

export const IntegrityPolicy = () => {
  let item = { number: 0 };
  return (
    <FlexBoxColumn
      mt="30px"
      className="noscrollbar"
      alignItems="flex-start"
      fontSize="18px"
      overflow="scroll"
      width="100%"
    >
      <TextBold fontSize="22px" width="100%">
        Integritetspolicy - FolkesBiluthyrningAB
      </TextBold>
      <PolicyHeader>A. Introduktion</PolicyHeader>
      <ListItem item={item}>
        Våra hemsidebesökares integritet är väldigt viktig för oss, och vi är
        fast beslutna att skydda den. Denna policy förklarar vad vi gör med dina
        personliga data.
      </ListItem>
      <ListItem item={item}>
        Genom att samtycka till att vi använder kakor i enlighet med villkoren i
        denna policy när du besöker vår hemsida för första gången tillåter du
        oss att använda kakor varje gång du besöker vår hemsida.
      </ListItem>
      <PolicyHeader>B. Erkännande</PolicyHeader>
      <Text mb="10px">
        Detta dokument skapades med en mall från SEQ Legal (seqlegal.com) och
        ändrades av Folkes Biluthyrning AB
      </Text>
      <PolicyHeader>C. Insamling av personlig information</PolicyHeader>
      <Text mb="10px">
        Följande typer av personlig information kan samlas in, lagras och
        användas:
      </Text>
      <ListItem item={item}>
        information om din dator, inklusive IP-adress, geografisk plats,
        webbläsartyp och -version samt operativsystem;
      </ListItem>
      <ListItem item={item}>
        information om dina besök på och din användning av denna hemsida,
        inklusive referenskällan, besökets längd, sidvisningar och hemsidans
        navigeringsvägar;
      </ListItem>
      <ListItem item={item}>
        information som du anger när du registrerar dig på vår hemsida, till
        exempel din e-postadress;
      </ListItem>
      <ListItem item={item}>
        information som du anger när du skapar en profil på vår hemsida, till
        exempel ditt namn, kön, relationsstatus, din födelsedag, dina
        profilbilder, intressen, hobbyer samt uppgifter om din utbildning och
        anställning;
      </ListItem>
      <ListItem item={item}>
        information som du anger för att prenumerera på våra e-postadress;
      </ListItem>
      <ListItem item={item}>
        information som du anger när du använder vår hemsidas tjänster;
      </ListItem>
      <ListItem item={item}>
        information som genereras när du använder vår hemsida, inklusive när,
        hur ofta och under vilka omständigheter du använder den;
      </ListItem>
      <ListItem item={item}>
        information som har att göra med allt du köper, tjänsterna du använder
        eller transaktioner som du gör via vår hemsida, inklusive ditt namn,
        telefonnummer, din adress, e-postadress och dina kreditkortsuppgifter;
      </ListItem>
      <ListItem item={item}>
        information som du publicerar på vår hemsida med avsikt att publicera
        den på internet, inklusive ditt användarnamn, dina profilbilder och
        innehållet i dina inlägg;
      </ListItem>
      <ListItem item={item}>
        information i alla former av kommunikation som du skickar till oss via
        e-post eller vår hemsida, inklusive dess innehåll och metadata;
      </ListItem>
      <ListItem item={item}>
        all annan personlig information som du skickar till oss.
      </ListItem>
      <Text mb="10px">
        Innan du lämnar ut en annan persons personliga information till oss
        behöver du ha den personens samtycke till att den personliga
        informationen både utlämnas och behandlas i enlighet med denna policy
      </Text>
      <PolicyHeader>D. Användning av din personliga information </PolicyHeader>
      <Text mb="10px">
        Personlig information som skickas till oss via vår hemsida kommer att
        användas för de syften som anges i denna policy eller på de relevanta
        sidorna på hemsidan. Vi kan använda din personliga information för
        följande:
      </Text>
      <ListItem item={item}>
        att administrera vår hemsida och verksamhet;
      </ListItem>
      <ListItem item={item}>att anpassa vår hemsida åt dig;</ListItem>
      <ListItem item={item}>
        att göra det möjligt för dig att använda tjänsterna som är tillgängliga
        på vår hemsida;
      </ListItem>
      <ListItem item={item}>
        att skicka varor som du har köpt via vår hemsida till dig;
      </ListItem>
      <ListItem item={item}>
        att leverera tjänster som är köpta via vår hemsida;
      </ListItem>
      <ListItem item={item}>
        att skicka kontoutdrag, fakturor och betalningspåminnelser till dig och
        samla in betalningar från dig;
      </ListItem>
      <ListItem item={item}>
        att skicka kommersiell kommunikation som inte är i
        marknadsföringssyften;
      </ListItem>
      <ListItem item={item}>
        att skicka e-postmeddelanden som du specifikt har begärt;
      </ListItem>
      <ListItem item={item}>
        att skicka vårt nyhetsbrev via e-post till dig om du har begärt det (du
        kan informera oss när som helst om du inte längre behöver nyhetsbrevet);
      </ListItem>
      <ListItem item={item}>
        att skicka marknadskommunikation till dig som rör vår verksamhet eller
        noggrant utvalda tredje parters verksamhet som vi tror att kan vara av
        intresse för dig per post eller, där du specifikt har samtyckt till
        detta, via e-post eller liknande teknologi (du kan informera oss när som
        helst om du inte längre behöver marknadskommunikation);
      </ListItem>
      <ListItem item={item}>
        att ge tredje parter statistisk information om våra användare (men dessa
        tredje parter kommer inte att kunna identifiera en enskild användare
        från informationen);
      </ListItem>
      <ListItem item={item}>
        att hantera förfrågningar och klagomål från eller om dig angående vår
        hemsida;
      </ListItem>
      <ListItem item={item}>
        att hålla vår hemsida säker och förebygga bedrägerier;
      </ListItem>
      <ListItem item={item}>
        att bekräfta ditt samtycke av vår hemsidas användarvillkor (inklusive
        övervakning av privata meddelanden som skickats via vår hemsidas privata
        meddelandetjänst); samt
      </ListItem>
      <ListItem item={item}>annan användning.</ListItem>
      <Text mb="10px">
        Om du skickar personlig information för publicering på vår hemsida,
        kommer vi att publicera och på annat sätt använda den informationen i
        enlighet med det tillstånd du ger oss.
      </Text>
      <Text mb="10px">
        Dina sekretessinställningar kan användas för att begränsa publiceringen
        av din information på vår hemsida och kan justeras med hemsidans
        sekretesskontroller.
      </Text>
      <Text mb="10px">
        Vi kommer inte att överlämna din personliga information till tredje part
        för deras eller någon annan tredje parts direkta marknadsföring utan
        ditt uttryckliga medgivande.
      </Text>
      <PolicyHeader>E. Utlämning av personlig information </PolicyHeader>
      <Text mb="10px">
        Vi kan lämna ut din personliga information till någon av våra anställda,
        styrelseledamöter, försäkringsbolag, professionella rådgivare, ombud,
        leverantörer eller underleverantörer som är rimligt nödvändigt för de
        syften som anges i denna policy.
      </Text>
      <Text mb="10px">
        Vi kan lämna ut din personliga information till alla medlemmar i vår
        företagsgrupp (detta innebär våra dotterbolag, vårt holdingbolag och
        alla dess dotterbolag) som är rimligt nödvändigt för de syften som anges
        i denna policy.
      </Text>
      <Text mb="10px">Vi kan utlämna din personliga information:</Text>
      <ListItem item={item}>
        i den mån vi är skyldiga att göra det enligt lag;
      </ListItem>
      <ListItem item={item}>
        i samband med pågående eller framtida domstolsförfaranden;
      </ListItem>
      <ListItem item={item}>
        för att upprätta, utöva eller försvara våra juridiska rättigheter
        (inklusive att lämna ut information till andra i syfte att förebygga
        bedrägerier och förminska kreditrisken);
      </ListItem>
      <ListItem item={item}>
        till köparen (eller den blivande köparen) av alla företag eller
        tillgångar som vi är i process (eller överväger) att sälja; och
      </ListItem>
      <ListItem item={item}>
        till alla personer som vi rimligen anser att kan begära av domstol eller
        annan behörig myndighet att vi lämnar ut den personliga informationen
        om, enligt vår rimliga mening, en sådan domstol eller myndighet rimligen
        skulle kunna beordra att vi utlämnar den personliga informationen.
      </ListItem>
      <Text mb="10px">
        Vi kommer inte att lämna ut din personliga information till tredje part
        förutom i de fall som anges i denna policy.
      </Text>
      <PolicyHeader>F. Internationell dataöverföring </PolicyHeader>
      <ListItem item={item}>
        Information som vi samlar in kan lagras, behandlas i och överföras
        mellan något av de länder vi är verksamma i för att göra det möjligt för
        oss att använda informationen i enlighet med denna policy.
      </ListItem>
      <ListItem item={item}>
        Information som vi samlar in kan överföras till följande länder som
        saknar dataskyddslagar motsvarande de som gäller i Europeiska ekonomiska
        samarbetsområdet: USA, Ryssland, Japan, Kina och Indien.
      </ListItem>
      <ListItem item={item}>
        Personlig information som du publicerar på vår hemsida eller skickar in
        för publicering på vår hemsida kan vara tillgänglig via internet världen
        runt. Vi kan inte förhindra att informationen används
      </ListItem>
      <ListItem item={item}>
        Du samtycker uttryckligen till överföring av personlig information som
        beskrivs i detta avsnitt F.
      </ListItem>
      <PolicyHeader>G. Bevarande av personlig information</PolicyHeader>
      <ListItem item={item}>
        Detta avsnitt G beskriver våra policyer och förfaranden för datalagring,
        som är utformade för att se till att vi följer våra juridiska
        skyldigheter angående lagring och radering av personlig information.
      </ListItem>
      <ListItem item={item}>
        Personlig information som vi behandlar för något syfte eller syften
        kommer inte att förvaras längre än vad som är nödvändigt för det syftet
        eller de syftena.
      </ListItem>
      <ListItem item={item}>
        Oavsett övriga bestämmelser i detta avsnitt G, kommer vi att behålla
        dokument (inklusive elektroniska dokument) som innehåller
        personuppgifter:
      </ListItem>
      <SubListItem>
        {[
          "i den mån vi är skyldiga att göra det enligt lag;",
          "om vi tror att handlingarna kan vara relevanta för pågående eller framtida domstolsförfaranden; och",
          "för att upprätta, utöva eller försvara våra juridiska rättigheter (inklusive att lämna ut information till andra i syfte att förebygga bedrägerier och förminska kreditrisken).",
        ]}
      </SubListItem>
      <PolicyHeader>H. Din personliga informations säkerhet </PolicyHeader>
      <ListItem item={item}>
        Vi kommer att vidta rimliga tekniska och organisatoriska ändring av din
        personliga information.
      </ListItem>
      <ListItem item={item}>
        Vi kommer att lagra all personlig information som du servrar.
      </ListItem>
      <ListItem item={item}>
        Alla elektroniska finansiella transaktioner som görs via vår hemsida
        skyddas av krypteringsteknologi.
      </ListItem>
      <ListItem item={item}>
        Du medger att informationsöverföring via internet i sig är osäkert, och
        vi kan inte garantera säkerheten för data som skickas via internet.
      </ListItem>
      <ListItem item={item}>
        Du ansvarar för att hålla lösenordet du använder för att komma åt vår
        hemsida konfidentiellt; vi kommer inte att be dig om ditt lösenord
        (förutom när du loggar in på vår hemsida).
      </ListItem>
      <PolicyHeader>I. Ändringar</PolicyHeader>
      <Text mb="10px">
        Vi kan uppdatera denna policy då och då genom att publicera en ny
        version på vår hemsida. Du bör kontrollera denna sida emellanåt för att
        se till att du förstår eventuella ändringar i denna policy. Vi kan
        meddela dig om ändringar av denna policy via e-post eller via vår
        hemsidas privata meddelandesystem.
      </Text>
      <PolicyHeader>J. Dina rättigheter </PolicyHeader>
      <Text mb="10px">
        Du kan be oss att ge dig all personlig information vi har om dig;
        tillhandahållande av sådan information kommer att omfattas av följande:
      </Text>
      <ListItem item={item}>betalning av en avgift och</ListItem>
      <ListItem item={item}>
        tillhandahållande av lämpligt bevis på din identitet. Vi accepterar
        vanligtvis ett foto av ditt pass som har certifierats av en notarie,
        plus en kopia av en samhällsserviceräkning som visar din aktuella
        adress.
      </ListItem>
      <Text mb="10px">
        Vi kan undanhålla den personliga information som du begär enligt vad som
        tillåts enligt lag.
      </Text>
      <Text mb="10px">
        Du kan när som helst be oss att inte behandla din personliga information
        för marknadsföringsändamål. acceptera vår användning av din personliga
        information för marknadsföringsändamål, eller så kommer vi att ge dig
        möjligheten att välja bort användningen av din personliga information
        för marknadsföringsändamål.
      </Text>
      <PolicyHeader>K. Tredje parts hemsidor </PolicyHeader>
      <Text mb="10px">
        Vår hemsida innehåller hyperlänkar till och detaljer om tredje parts
        hemsidor. Vi har ingen kontroll över, och är inte ansvariga för, tredje
        parters sekretesspolicyer och praxis.
      </Text>
      <PolicyHeader>L. Uppdatering av information </PolicyHeader>
      <Text mb="10px">
        Låt oss veta om den personliga information som vi har om dig behöver
        korrigeras eller uppdateras.
      </Text>
      <PolicyHeader>M. Kakor </PolicyHeader>
      <Text mb="10px">
        Vår hemsida använder kakor. En kaka är en fil som innehåller en
        identifierare (en sträng med bokstäver och siffror) som skickas av en
        webbserver till en webbläsare och lagras av webbläsaren. Identifieraren
        skickas därefter tillbaka till servern varje gång webbläsaren begär en
        sida från servern. Kakor kan antingen vara “ständiga kakor” eller
        “sessionskakor”: en ständig kaka lagras av en webbläsare och förblir
        giltig till det angivna utgångsdatumet, såvida den inte raderas av
        användaren innan utgångsdatumet; en sessionskaka, å andra sidan, kommer
        att gå ut i slutet av användarsessionen, när webbläsaren är stängd.
        Kakor innehåller vanligtvis inte information som personligen
        identifierar en användare, men personlig information som vi lagrar om
        dig kan vara länkad till informationen som är lagrad i och erhållen från
        kakor. Vi använder både ständiga och sessionskakor på vår hemsida.
      </Text>
      <ListItem item={item}>
        Nedan anges namnen på de kakor vi använder på vår hemsida och de syften
        som de används för:
      </ListItem>
      <SubListItem>
        {[
          "vi använder Google Analytics och Adwords på vår hemsida för att känna igen en dator när en användare besöker hemsidan / spårar användare när de bläddrar på hemsidan / förbättrar hemsidans användbarhet / analyserar användningen av hemsidan / administrerar hemsidan / förhindrar bedrägerier och förbättrar hemsidans säkerhet / anpassar hemsidan för varje användare / målannonser som kan vara av särskilt intresse för specifika användare",
        ]}
      </SubListItem>
      <ListItem item={item}>
        De flesta webbläsare låter dig vägra att acceptera kakor – till exempel:
      </ListItem>
      <SubListItem>
        {[
          "i Internet Explorer (version 10) kan du blockera kakor med hjälp av inställningarna för att upphäva kakhantering genom att klicka på “Verktyg”, “Internetalternativ”, “Sekretess” och sedan “Avancerat”;",
          "i Firefox (version 24) kan du blockera alla kakor genom att klicka på “Verktyg”, “Inställningar”, “Sekretess & säkerhet”, välja “Anpassad innehållsblockering” och klicka på “Alla kakor”; och ",
          "i Chrome (version 29) kan du blockera alla kakor genom att öppna menyn “Inställningar” och klicka på “Inställningar för webbplatsen” under “Sekretess och säkerhet” och sedan avmarkera “Tillåt att webbplatser sparar och läser cookiedata”.  ",
        ]}
      </SubListItem>
      <Text>
        Om du blockerar alla kakor kommer det att ha en negativ inverkan på
        många hemsidors användbarhet. Om du blockerar kakor kommer du inte att
        kunna använda alla funktioner på vår hemsida.
      </Text>
      <ListItem item={item}>
        Du kan ta bort kakor som redan har lagrats på din dator – till exempel:
      </ListItem>
      <SubListItem>
        {[
          "i Internet Explorer (version 10) måste du manuellt ta bort kakor (du kan hitta instruktioner för att göra det på http://support.microsoft.com/kb/278835 ); ",
          "i Firefox (version 24) kan du ta bort kakor genom att klicka på “Verktyg”, “Inställningar”, “Sekretess & säkerhet” och sedan välja “Rensa data” under “Kakor och webbplatsdata”; och ",
          "i Chrome (version 29) kan du ta bort alla kakor genom att öppna menyn “Inställningar” och klicka på “Rensa webbinformation” under “Sekretess och säkerhet”.  ",
        ]}
      </SubListItem>
      <ListItem item={item}>
        Tar du bort kakor kommer det att ha en negativ inverkan på många
        hemsidors användbarhet.
      </ListItem>
    </FlexBoxColumn>
  );
};