import { Box } from "@material-ui/core";
import React, { useContext,  useState } from "react";
import { Context } from "../../context/Store";
import FlexBoxColumn from "./../flexBox/FlexBoxColumn";
import { IntegrityPolicy } from "./../integrityPolicy/IntegrityPolicy";
import StyledButton from "./../styledButton/StyledButton";
import { Text, TextBold } from "./../text/Text";

const CookieBanner = () => {
  const [cookiesApproved, setCookiesApproved] = useState(
    localStorage.getItem("cookies") === "true"
  );

  const { setPopUp } = useContext(Context);

  const approve = () => {
    localStorage.setItem("cookies", "true");
    setCookiesApproved(true);
  };
  const openPolicy = () => {
    setPopUp(IntegrityPolicy);
  };

  return (
    !cookiesApproved && (
      <FlexBoxColumn
        width="100%"
        zIndex="100"
        position="sticky"
        bottom="0px"
        style={{ background: "#DDD" }}
      >
        <Box
          display="flex"
          flexDirection={["column", "column", "row"]}
          alignItems="center"
          width="1200px"
          maxWidth="100%"
          py="20px"
        >
          <Text textAlign={["center", "center", "left"]} px="50px">
            Vår webbplats använder sig av cookies för att förbättra
            funktionaliteten för dig som användare och förbättra din
            användarupplevelse. Genom att fortsätta använda vår webbplats
            godkänner du våra cookies
          </Text>
          <Box pt={["20px", "20px", "0"]} pr={["0", "0", "40px"]}>
            <Box>
              <StyledButton onClick={approve}>
                <TextBold width="140px" fontSize="16px">
                  Ok
                </TextBold>
              </StyledButton>
            </Box>
            <Box mt="10px">
              <StyledButton onClick={openPolicy}>
                <TextBold width="140px" fontSize="16px">
                  Integritetspolicy
                </TextBold>
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </FlexBoxColumn>
    )
  );
};

export default CookieBanner;
